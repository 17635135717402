import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UtilsService } from '../utils/utils.service';
import { Observable } from 'rxjs';
import { LoginInfo } from '../../models/login/login-info.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private router = inject(Router);
  private utilService = inject(UtilsService)

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const loginInfo: LoginInfo = this.utilService.getToken() as LoginInfo;

      if (!loginInfo || !loginInfo.accessToken || !loginInfo.idToken) {
      window.location.href = `/unauthorized?redirect=${encodeURIComponent(state.url)}`;

      return false;
    }
    return true;
  }
}
