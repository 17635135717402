import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { MessageService } from '../messages/message.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CacheService } from '../cache/cache.service';
import { Observable, OperatorFunction, catchError, map, of, throwError } from 'rxjs';
import { serverUrl } from '../../constant/constants';
import { HttpRequestObj } from '../../models/request-response/http-request.model';
import { HttpResponseObj } from '../../models/request-response/http-response.model';

@Injectable({
    providedIn: 'root'
})

export class CommonHttpService {
    constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private cacheService : CacheService
    ){}

    profileId!: string;
    preRequestUrl: string = serverUrl;

    gets<T>(requestObj: HttpRequestObj<T>): Observable<T> {
        return this.http.get(this.preRequestUrl + requestObj.uri).pipe(
          map((response: HttpResponseObj<T>) => {
            if (requestObj.message && requestObj.message.successMessage && requestObj.showToastr) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response.Result;
          }) as OperatorFunction<Object, T>,
          catchError((error) => {
            
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
          }));
    }

    /**
     * @description: Common get method
     * @returns: Return the generic response
     */
    get<T>(requestObj: HttpRequestObj<T>): Observable<T> {
        
        return this.http.get(this.preRequestUrl + requestObj.uri).pipe(
        map((response: any) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);                
            }            
            return response.Result;
        }),
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
            // return throwError(() => "something went wrong");
        }));
    }

    /**
     *  @description: Common get method with custom error message
     *  @param uri: Pass the get url + query string parameters
     *  @returns: Return the generic response
     */
    getWithCustomErrorMessage<T>(uri: string): Observable<T> {
        return this.http.get(this.preRequestUrl + uri).pipe(
        map((response: HttpResponseObj<T>) => {
            return response.Result;
        }) as OperatorFunction<Object, T>);
    }

    /**
     * @description: Common Post method
     * @returns: Returns the inserted object
     */
    post<T, R>(requestObj: HttpRequestObj<T>): Observable<R> {
        
        return this.http.post(this.preRequestUrl + requestObj.uri, requestObj.object).pipe(
        map((response: HttpResponseObj<R>) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response.Result;
        }) as OperatorFunction<Object, R>,
        catchError((error) => {   
            return this.errorHandlerService.handleError(error, requestObj.message  || null , requestObj.showToastr);
        }));
    }
    
    /**
     * @description: Post method with upload progress response
     * @returns: Returns the upload progress percentage or the inserted object
     */
    postWithProgress<T, R>(requestObj: HttpRequestObj<T>): Observable<R> {
        return this.http.post(this.preRequestUrl + requestObj.uri, requestObj.object, {
            reportProgress: true,
            observe: 'events'
        }).pipe(
            map((event: any) => {
                switch (event.type) {
                case HttpEventType.UploadProgress:
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    return percentDone;
                case HttpEventType.Response:
                    if (requestObj.message && requestObj.message.successMessage) {
                    this.messageService.showSuccessMessage(requestObj.message.successMessage);
                    }
                    return event.body.Result;
                }
            }),
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
        })
        );
    }

    /**
     * @description: Common Put method
     * @returns: Returns the updated object
     */
    put<T, R>(requestObj: HttpRequestObj<T>): Observable<R> {

        return this.http.put(this.preRequestUrl + requestObj.uri, requestObj.object).pipe(
        map((response: HttpResponseObj<R>) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response.Result;
        }) as OperatorFunction<Object, R>,
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
        }));
    }

    /**
     * @description: Common Delete method
     * @returns: Returns the generic type
     */
    delete<T>(requestObj: HttpRequestObj<T>): Observable<T> {

        return this.http.delete(this.preRequestUrl + requestObj.uri, {body : requestObj.object}).pipe(
        map((response: HttpResponseObj<T>) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response.Result;
        }) as OperatorFunction<Object, T>,
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
        }));
    }

    /**
     * @description: Common Delete method using body
     * @param uri: Pass the delete url + query string parameters
     * @param body: Pass the object for the delete
     * @param message: Pass custom error message if wanted to show custom error message
     * @returns: Returns the generic type
     */
    deleteWithBody<T, R>(requestObj: HttpRequestObj<T>): Observable<R> {
        return this.http.request('delete', this.preRequestUrl + requestObj.uri, { body: requestObj.object }).pipe(
        map((response: HttpResponseObj<R>) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response.Result;
        }) as OperatorFunction<Object, R>,
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
        }));
    }

  
    /**
     * @description: Common resource download method
     * @param url: Pass the download resource url
     * @param message: Pass custom error message if wanted to show custom error message
     */
    downloadResource<T>(requestObj: HttpRequestObj<T>): Observable<any> {
        return this.http.get(this.preRequestUrl + requestObj.uri, { responseType: 'blob' }).pipe(
        map((response) => {
            if (requestObj.message && requestObj.message.successMessage) {
                this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return response;
        }),
        catchError((error) => {
            return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
        }));
    }

    /**
     * @description get Secured Authorized resource data
     * @param url - Url to get resource
     * @returns logged in users profile data
     */
    getSecuredResource(uri: string): Observable<SafeUrl> {
        const imagePath = uri.replace('/User/GetUserImage/', '');
        const eTagKey = 'e-tag-' + imagePath;
        const existURL = this.cacheService.retrieve(eTagKey);

        const headers = new HttpHeaders();
        if (existURL) {      
            return of(existURL);
        }

        return this.http
        .get(this.preRequestUrl + uri, {
            headers,
            responseType: 'blob',
            observe: 'body',
        })
        .pipe(
            map((response) => {
            const bypassedURL = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(response as Blob)
            );
            this.cacheService.store(eTagKey, bypassedURL);
            return bypassedURL;
            })
        );
    }

    getMediaResource(mediaName : string){

        const eTagKey = 'e-tag-' + mediaName;
        const existURL = this.cacheService.retrieve(eTagKey);

        const headers = new HttpHeaders();
        if (existURL) {      
            return of(existURL);
        }

        return this.http
        .get(this.preRequestUrl + '/Nugget/GetNuggetMedia/' + mediaName, {
            headers,
            responseType: 'blob',
            observe: 'body',
        })
        .pipe(
            map((response) => {
                const bypassedURL = this.sanitizer.bypassSecurityTrustUrl(
                    URL.createObjectURL(response as Blob)
                );
                this.cacheService.store(eTagKey, bypassedURL);
                return bypassedURL;
            })
        );
    }
    getExternalUrls(url:any) {
        return this.http.get(url);
    }
}