// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    isExpertEditable: false,
    authOptions: {
      clientId: 'ede4f381-5da6-4c8c-8cf6-5247e95d3b5a',
      tenantId: 'f610c0b7-bd24-4b39-810b-3dc280afb590',
      redirectUri: window.location.origin + '/dashboard',
      scopes: ['User.Read', 'openid', 'profile']
    },
    serverUrl: 'https://nok-d-webapp-api.azurewebsites.net/api',
    siteUrl: 'https://nokplatform-dev.who.int',
    maxRecommendationsCount: 5,
    storageUrl:'https://nokdsta.blob.core.windows.net',
    containerName:'development',
    nuggetMediaPath:'nuggets/media',
    nuggetThumbnailPath:'nuggets/thumbnail'
  }
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.