import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "../messages/message.service";
import { throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})

/*
 * Service to created to handle common errors returned from backend
 */
export class ErrorHandlerService {

    message!: string;
    constructor(
        private router: Router,
        private messageService: MessageService
    ){}

    /**
     * @description Handle error fot backend API
     * @param error error message
     */

    handleError(error : any, messageObj ?: any , isShow : boolean | undefined = true) {
        if(!messageObj || !messageObj.errorMessage ) {
            switch(error.status) {
                case 401:
                {
                    if (this.message != '') {
                        this.message = 'unauthorized Access';
                    }
                    if (localStorage.getItem('login-info') == '') 
                        this.router.navigate(['/home']);
                    break;
                }
                case 500:
                {
                    if (error && error.error.ResponseException) {
                        this.message =
                          (error.error.ResponseException.ValidationErrors && error.error.ResponseException.ValidationErrors[0])
                            ? 'Something went wrong.'
                            : error.error.ResponseException.ExceptionMessage
                              ? error.error.ResponseException.ExceptionMessage
                                : 'Something went wrong.';
                      } else {
                        this.message = 'Something went wrong.';
                      }
                    break;
                }
                case error.status >= 400 && error.status <= 499:
                    this.message = 'Something went wrong.';
                    break;
                default:
                    this.message = 'Something went wrong.';
                    break;
            }
        }
        else 
            this.message = messageObj.errorMessage;

        if ((!messageObj || messageObj.showMessage === undefined || messageObj.showMessage) && isShow) {
            this.messageService.showErrorMessage(this.message);
        }
        return throwError(() => this.message);
    }
}