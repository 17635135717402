import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NokService } from '../nok/nok.service';
import { GetNuggetBySearchTermModel } from '../../models/category/get-category.model';

@Injectable({
  providedIn: 'root'
})
export class SharedvariablesService {
  private originalTotalPublishedNok: number = 0;

  private totalPublishedNokSubject = new BehaviorSubject<number>(0);
  totalPublishedNok$ = this.totalPublishedNokSubject.asObservable();

  constructor(private nokService: NokService) {

    this.initializeTotalPublishedNok();
  }

  private initializeTotalPublishedNok(): void {
    let searchRequestModel: GetNuggetBySearchTermModel = {
      searchTerm: '',
      isFromSearch: true,
      paginationFilter: {
        orderBy: "NuggetId",
        orderByAscending: false,
        pageIndex: 1,
        pageSize: 5,
        columnsAndValuesForFilter: {
        }
      },
    }
    this.nokService.getNuggetBySearchTerm(searchRequestModel)
    .subscribe({
      next: (data: any) => {
        if(data?.paginatedNuggets?.totalRows)
        {
          this.originalTotalPublishedNok = data?.paginatedNuggets?.totalRows;

          this.totalPublishedNokSubject.next(data?.paginatedNuggets?.totalRows);
        }
      },
      error: (error) => {
        console.error('Error fetching total number of published nuggets:', error);
      }
    });
  }

  setTotalPublishedNok(value: number): void {
    this.totalPublishedNokSubject.next(value);
  }

  getTotalPublishedNok(): number {
    return this.totalPublishedNokSubject.getValue();
  }
  resetTotalPublishedNok(): void {
    this.totalPublishedNokSubject.next(this.originalTotalPublishedNok);
  }
}
