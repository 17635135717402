<div class="custom-modal-sm">
    <div class="custom-modal-sm-content">
      <div class="d-flex justify-content-between">
        <div>
          <h4>Need assistance?</h4>
          <p class="d-block font-size-14 mt-2 text-muted"> Please enter the details below describing the issue. </p>
        </div>
        <div id="assistance_Back" (click)="navigateToHomeComponent()"><i class="bi bi-x-lg cursor-pointer font-size-22"></i></div>
      </div>
      <div class="hr my-2"></div>
      <div class="form_scroll_bar scroll_bar__420 cst___scroll_bar">
        <form [formGroup]="assistanceForm" (ngSubmit)="submitForm()">
          <div class="row mt-4">
            <div class="col-12 mb-3">
              <div class="custom-input-lg">
                <label for="firstName" class="form-label">First Name*</label>
                <input type="text" class="form-control" id="firstName" formControlName="firstName" [class.is-invalid]="assistanceForm.get('firstName')!.invalid && assistanceForm.get('firstName')!.touched">
                <div *ngIf="assistanceForm.get('firstName')!.invalid && assistanceForm.get('firstName')!.touched" class="invalid-feedback">
                  Please provide a first name.
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="custom-input-lg">
                <label for="lastName" class="form-label">Last Name*</label>
                <input type="text" class="form-control" id="lastName" formControlName="lastName" [class.is-invalid]="assistanceForm.get('lastName')!.invalid && assistanceForm.get('lastName')!.touched">
                <div *ngIf="assistanceForm.get('lastName')!.invalid && assistanceForm.get('lastName')!.touched" class="invalid-feedback">
                  Please provide a last name.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <div class="custom-input-lg">
                <label for="email" class="form-label">Email*</label>
                <input type="email" class="form-control" id="email" formControlName="email" [class.is-invalid]="assistanceForm.get('email')!.invalid && assistanceForm.get('email')!.touched">
                <div *ngIf="assistanceForm.get('email')!.invalid && assistanceForm.get('email')!.touched" class="invalid-feedback">
                  Please provide a valid email address.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <div class="custom-input-lg">
                <label for="description" class="form-label">Description*</label>
                <textarea class="form-control" id="description" formControlName="description" rows="3" [class.is-invalid]="assistanceForm.get('description')!.invalid && assistanceForm.get('description')!.touched"></textarea>
                <div *ngIf="assistanceForm.get('description')!.invalid && assistanceForm.get('description')!.touched" class="invalid-feedback">
                  Please provide a description.
                </div>
              </div>
            </div>
          </div>
          <div class="text-end pb-3">
            <a id="assistance_Cancel" class="text-blue font-size-16 pe-3" (click)="navigateToHomeComponent()">Cancel</a>
            <button id="assistance_Submit" type="submit" class="btn btn-blue" [disabled]="!assistanceForm.valid"><i class="bi bi-check-circle pe-1"></i> Submit</button>
          </div>
        </form>
      </div>
    </div>
</div>
  