import { Injectable, inject } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { Observable } from 'rxjs';
import { EmailNotificationModel } from '../../models/email-notification/email-notification.model';
import { SortingRequest } from '../../models/sorting-request/sorting-request-model';

@Injectable({
  providedIn: 'root',
})
export class EmailNotificationService {
  constructor() {}

  private http = inject(CommonHttpService);

  getAllNotificationTemplate(
    data : SortingRequest
  ): Observable<EmailNotificationModel[]> {
    return this.http.post<any,EmailNotificationModel[]>({
      uri: '/EmailNotification/GetAll',
      object: {
        sortingFilter: data
      },
      showToastr: true,
    });
  }

  getEmailNotificationTemplateById(
    templateId: number
  ): Observable<EmailNotificationModel> {
    return this.http.get<EmailNotificationModel>({
      uri: '/EmailNotification/' + templateId + '',
    });
  }

  saveEmailNotificationDetails(
    data: EmailNotificationModel
  ): Observable<EmailNotificationModel> {
    return this.http.post<EmailNotificationModel, EmailNotificationModel>({
      uri: '/EmailNotification/',
      object: data,
      showToastr: true,
    });
  }

  updateEmailNotificationDetails(
    data: EmailNotificationModel
  ): Observable<EmailNotificationModel> {
    return this.http.put<EmailNotificationModel, EmailNotificationModel>({
      uri: '/EmailNotification/',
      object: data,
      showToastr: true,
    });
  }

  deleteEmailNotification(emailTemplateId: number) {
    const rsp = {
      emailTemplateID: emailTemplateId
    }

    return this.http.delete({
      uri: '/EmailNotification/',
      object: rsp,
      showToastr: true
    });
  }
}
