import { PaginationRequest } from "../pagination-request/pagination-request-model";

export interface GetCategoryModel {
  includeHazard: boolean;
  includeTechnicalArea: boolean;
  includeCapacity: boolean;
  includeRegion: boolean;
  includePersonType: boolean;
  includeNOKSourceType: boolean;
}

export interface GetNuggetByIdModel {
  nuggetId: number;
  includeRelatedDetails: boolean
}

export interface GetNuggetBySearchTermModel {
  searchTerm: string;
  paginationFilter: PaginationRequest,
  isFromSearch: boolean 
  startDate?: Date | null,
  endDate?: Date | null
}

export interface GetCategoryResponse {
  hazardTypes: HazardType[]
  ihrTechnicalAreas: IhrTechnicalArea[]
  ihrCapacities: IhrCapacity[]
  regions: Region[]
  personTypes: PersonType[]
  nokSourceTypes: NokSourceType[]
}

export interface HazardType {
  hazardTypeId: number
  name: string
  isDeleted: boolean
  hazardClusters: HazardCluster[]
}

export interface HazardCluster {
  hazardClusterId: number
  hazardTypeId: number
  hazardTypeName: string,
  name: string
  isDeleted: boolean
  hazardValues: HazardValue[]
}

export interface HazardValue {
  hazardValueId: number
  hazardClusterId: number,
  hazardClusterName: string,
  name: string
  isDeleted: boolean
}

export interface IhrTechnicalArea {
  ihrTechnicalAreaId: number
  name: string
  isDeleted: boolean
  ihrLevels: IhrLevel[]
}

export interface IhrLevel {
  ihrLevelId: number
  name: string
  isDeleted: boolean
  ihrTechnicalAreaId: number;
  ihrTechnicalAreaName: string;
  ihrSpecificLevels: IhrSpecificLevel[]
}

export interface IhrSpecificLevel {
  ihrSpecificLevelId: number
  ihrLevelId: number,
  ihrLevelName: string,
  name: string
  isDeleted: boolean
}

export interface IhrCapacity {
  ihrCapacityId: number
  name: string
  isDeleted: boolean
  ihrSpecificCapacities: IhrSpecificCapacity[]
}

export interface IhrSpecificCapacity {
  ihrSpecificCapacityId: number
  ihrCapacityId: number,
  ihrCapacityName: string,
  name: string
  isDeleted: boolean
}

export interface Continent {
  continentId: number
  name: string
  regions: Region[]
}

export interface Region {
  regionId: number
  name: string
  countries: Country[]
}

export interface Country {
  countryId: number
  name: string
  isoCode?: string | null
  isO3Code?: string | null
  latitude?: number | null
  longitude?: number | null
  region?: string | null
  currencyId?: any | null
  currency?: any | null
  regionId: number
}

export interface PersonType {
  personTypeId: number
  name: string
}

export interface NokSourceType {
  nokSourceTypeId: number
  name: string
}


// NOK Models, will be taken to seperate files.


export interface ManageNuggetCommand {
  nuggetId: number;
  title: string;
  subTitle: string;
  shortDescription: string;
  description: string;
  continentId: number | null;
  countryId: number | null;
  formatType: number;
  textType: number;
  status: number;
  thumbnail: string;
  tempThumbnailPath:string;
  media: string;
  tempMediaPath:string;
  transcript:string;
  startDate: string | null;
  endDate: string | null;
  endDateStatus: number | null;
  referenceLink: string | null;
  reason: string | null;
  nuggetKeywordsModel: any[];
  nuggetTagsModel: any[];

  nuggetDescriptionLines: NuggetDescriptionLine[],
  nuggetHazardTypes: NuggetHazardType[],
  nuggetIHRTechnicalAreas: NuggetIHRTechnicalArea[],
  nuggetIHRCapacities: NuggetIHRCapacity[],
  nuggetTags: NuggetTag[],
  nuggetKeywords: NuggetKeyword[],
  nuggetRegions: NuggetRegion[],
  nuggetPersonTypes: NuggetPersonType[],
  nuggetSourceTypes: NuggetSourceType[],
  nuggetAuthors: NuggetAuthor[],
  nuggetCheckins?: NuggetCheckin[],
  submittedByUser?: string;
  submittedByRole?: string;
  submittedDate?: string;
  createdByUser?: string;
  displayCategory?: DisplayCategory;
  submittedBy? : number,
}

export interface NuggetCheckin {
  userId: number,
  email: string,
  nuggetId: number,
}


export interface DisplayCategory {
  tooltip: string,
  value: string
}

export interface NuggetRegion {
  regionId: number,
  nuggetCountries: NuggetCountry[]
}
export interface NuggetCountry {
  countryId: number,
}
export interface NuggetDescriptionLine {
  nuggetDescriptionLineId?: string,
  description?: string,
  isOrdered: boolean,
  isNew?: boolean,
  parentLineId?: number,
  childLines: NuggetDescriptionLine[]
}

export interface NuggetHazardType {
  hazardTypeId: number,
  name: string,
  nuggetHazardClusters: NuggetHazardCluster[]
}
export interface NuggetHazardCluster {
  hazardClusterId: number,
  name: string,
  nuggetHazardValues: NuggetHazardValue[]
}

export interface NuggetHazardValue {
  name: string
  hazardValueId: number
}


export interface NuggetIHRTechnicalArea {
  ihrTechnicalAreaId: number,
  name: string,
  nuggetIHRLevels: NuggetIHRLevel[]
}
export interface NuggetIHRLevel {
  ihrLevelId: number,
  name: string,
  nuggetIHRSpecificLevels: NuggetIHRSpecificLevel[]
}

export interface NuggetIHRSpecificLevel {
  name: string
  ihrSpecificLevelId: number
}

export interface NuggetIHRCapacity {
  ihrCapacityId: number,
  name: string,
  nuggetIHRSpecificCapacities: NuggetIHRSpecificCapacity[]
}
export interface NuggetIHRSpecificCapacity {
  ihrSpecificCapacityId: number,
  name: string
}
export interface NuggetTag {
  title: string
}
export interface NuggetKeyword {
  title: string
}
export interface NuggetPersonType {
  personTypeId: number;
}
export interface NuggetSourceType {
  nokSourceTypeId: number
}
export interface AutoSuggestResponse {
  Message: string;
  Result: any;
  StatusCode: number;
  Version: string;
}

export interface NuggetAuthor {
  nuggetAuthorId?: string,
  nuggetId?: number,
  title: string,
  name: string,
  countryId: number,
  year?: number,
  date?: Date,
  isNew?: boolean
}

export interface ManageChekinsCommand {
  nuggetId: number,
  emails: string[],
}

export interface UpdateStatusCommand {
  nuggetId: number,
  status: number,
  reason: string
}

export enum NuggetStatus {
  Draft = 1,
  Submit = 2,
  Checkin = 3,
  Return = 4,
  Reject = 5,
  Published = 6,
  Archived = 7,
  Recall = 8
}

export interface GetNuggetsCommand {
  nuggetId: number,
  searchTerm: string,
  selectedTabName: string,
  paginationFilter: PaginationRequest
}

export interface ReturnRejectReviewerModal {
  title: string,
  show: boolean,
  message: string,
  buttonTitle: string,
  modalOperation: number,
  nuggetId: number,
  reason: string,
  disableButtons: boolean,
  reviewerEmails:string[]
}

export interface NuggetViewsCommand {
  startDate: Date,
  endDate: Date
}

export interface MultiSelectItem
{
  label: string;
}
export interface RelatedCategory {
  id: number;
  name: string;
  count: number;
  type: string;
}

export interface GetNuggetsVisualCommand
{
  category: string;
}

// Interface for the nested visualModels objects
export interface VisualModel {
  id: number;
  name: string;
  count: number;
  type: string;
  visualModels?: VisualModel[]; // Optional property for nested structures
}
