import { Routes } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AuthGuard } from './core/services/auth-guard/auth-guard.service';
import { CreateNokComponent } from './create-nok/create-nok.component';
import { ProfilepageComponent } from './shared/components/profilepage/profilepage.component';
import { userAccess } from './core/constant/userAccess.constant';
import { RoleGuard } from './core/services/guard/role.guard';
import { FaqComponent } from './shared/components/faq/faq.component'
import { AssistanceComponent } from './shared/components/assistance/assistance.component';
import { NuggetDetailParaComponent } from './shared/components/home-content/nugget-detail-para/nugget-detail-para.component';
import { NuggetListComponent } from './nugget-list/nugget-list.component';
import { NuggetPreviewComponent } from './shared/components/home-content/nugget-preview/nugget-preview.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { UnautorizedAccessComponent } from './shared/components/unautorized-access/unautorized-access.component';
import { EmailNotificationComponent } from './admin/email-notification/email-notification.component';
import { EmailNotificationListComponent } from './admin/email-notification/email-notification-list/email-notification-list.component';
import { AssistanceListComponent } from './shared/components/assistance/assistance-list/assistance-list.component';




export const routes: Routes = [
    { 
        path : '',
        redirectTo: 'home',
        pathMatch : 'full' 
    },
    { 
        path : 'home', 
        loadChildren: () => import('./shared/components/home-content/home-content.routes').then(c => c.HOMECONTENT_ROUTES),
        data: {
            role: [...userAccess['Home']]
        }
    },
    { 
        path : 'faq', 
        component: FaqComponent, 
    },
    { 
        path : 'assistance', 
        component: AssistanceComponent, 
    },
    {
        path: 'assistance/list',
        component: AssistanceListComponent,
        canActivate: [AuthGuard, RoleGuard],
        data : {
            role: [...userAccess['Setting']] 
        }
    },
    { 
        path : 'dashboard', 
        component: DashboardComponent, 
        canActivate: [AuthGuard, RoleGuard],
        data: {
            role: [...userAccess['dashboard']]
        }
     },
     { 
        path : 'usermanagement', 
        loadChildren: () => import('./admin/user-management/user-management.routes').then(c => c.USERMANAGEMENT_ROUTES), 
        canActivate: [AuthGuard, RoleGuard],
        data: {
            role: [...userAccess['User Management']]
        }
    },
    {   
        path : 'profile', 
        component: ProfilepageComponent,
        canActivate: [AuthGuard]
    },
    { path : 'nok/create', component: CreateNokComponent, canActivate: [AuthGuard]},
    { path : 'nok/edit/:nuggetId', component: CreateNokComponent, canActivate: [AuthGuard] },
    { path : 'nok/list', component: NuggetListComponent, canActivate: [AuthGuard] },
    { path : 'nok/preview/:nuggetId', component: NuggetPreviewComponent, canActivate: [AuthGuard]},
    { 
        path : 'settings', 
        loadChildren: () => import('./admin/settings/datasets/datasets-header/datasets-header.routes').then(c => c.DATASET_ROUTES),
        canActivate: [AuthGuard, RoleGuard],
        data: {
            role: [...userAccess['Setting']] 
        }
    },
    {
        path: 'email-notification/list',
        component: EmailNotificationListComponent,
        canActivate: [AuthGuard, RoleGuard],
        data : {
            role: [...userAccess['Setting']] 
        }
    },
    {
        path: 'email-notification/create',
        component: EmailNotificationComponent,
        canActivate: [AuthGuard, RoleGuard],
        data : {
            role: [...userAccess['Setting']] 
        }
    },
    {
        path: 'email-notification/edit/:id',
        component: EmailNotificationComponent,
        canActivate: [AuthGuard, RoleGuard],
        data : {
            role: [...userAccess['Setting']] 
        }
    },
    { path : 'nok-details/:id' , component: NuggetDetailParaComponent},
    { path : 'unauthorized' , component: UnautorizedAccessComponent},
    { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page

]
