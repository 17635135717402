<div id="signUp" class="sign-up-modal">
    <div class="custom-modal-sm-content">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Sign Up</h4>
            <div id="signUp_Back" (click)="navigateToHomePage()"><i class="bi bi-x-lg cursor-pointer font-size-22"></i></div>
        </div>
        <div class="hr my-2"></div>
        <div class="form_scroll_bar">
            <form [formGroup] = "signUpForm" (ngSubmit)="signUpUser()">

                <div class="row mt-4">
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="firstname" class="form-label">First Name</label>
                            <input type="text" class="form-control" id="firstname" formControlName="firstname" [class.is-invalid]="signUpForm.get('firstname')!.invalid && signUpForm.get('firstname')!.touched">
                            <div *ngIf="signUpForm.get('firstname')!.invalid && signUpForm.get('firstname')!.touched" class="invalid-feedback">
                                Please provide a first name.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="lastname" class="form-label">Last Name</label>
                            <input type="text" class="form-control" id="lastname" formControlName="lastname" [class.is-invalid]="signUpForm.get('lastname')!.invalid && signUpForm.get('lastname')!.touched" >
                            <div *ngIf="signUpForm.get('lastname')!.invalid && signUpForm.get('lastname')!.touched" class="invalid-feedback">
                               Please provide a last name.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="jobtitle" class="form-label">Job Title</label>
                            <input type="text" class="form-control" id="jobtitle" formControlName="jobtitle" [class.is-invalid]="signUpForm.get('jobtitle')!.invalid && signUpForm.get('jobtitle')!.touched" >
                        </div>
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="institution" class="form-label">Institution</label>
                            <input type="text" class="form-control" id="institution" formControlName="institution" [class.is-invalid]="signUpForm.get('institution')!.invalid && signUpForm.get('institution')!.touched" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="countryId" class="form-label">Country</label>
                            <select class="form-select" aria-label="Default select example" formControlName="countryId" [class.is-invalid]="signUpForm.get('countryId')!.invalid && signUpForm.get('countryId')!.touched">
                               <option *ngFor="let country of countryList" [value]="country.countryId">{{country.name}}</option>
                            </select>
                            <div *ngIf="signUpForm.get('countryId')!.invalid && signUpForm.get('countryId')!.touched" class="invalid-feedback">
                               Please select a valid country.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" formControlName="email" [class.is-invalid]="signUpForm.get('email')!.invalid && signUpForm.get('email')!.touched">
                            <div *ngIf="signUpForm.get('email')!.invalid && signUpForm.get('email')!.touched" class="invalid-feedback">
                               Please provide a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="roleId" class="form-label">Role</label>
                            <select class="form-select" aria-label="Default select example" formControlName="roleId"  [class.is-invalid]="signUpForm.get('roleId')!.invalid && signUpForm.get('roleId')!.touched" >
                              <option *ngFor="let role of allRoles" [value]="role.roleId">{{role.role}}</option>
                            </select>
                            <div *ngIf="signUpForm.get('roleId')!.invalid && signUpForm.get('roleId')!.touched" class="invalid-feedback">
                              Please select a role.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="affiliation" class="form-label">Affiliation</label>
                            <input type="text" class="form-control" id="affiliation" formControlName="affiliation" [class.is-invalid]="signUpForm.get('affiliation')!.invalid && signUpForm.get('affiliation')!.touched" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="custom-input-lg">
                            <label for="accessReason" class="form-label">Reason for Access</label>
                            <textarea class="form-control" id="accessReason" rows="3" formControlName="accessReason" [class.is-invalid]="signUpForm.get('accessReason')!.invalid && signUpForm.get('accessReason')!.touched" ></textarea>
                            <div *ngIf="signUpForm.get('accessReason')!.invalid && signUpForm.get('accessReason')!.touched" class="invalid-feedback">
                              Please input the reason for the access.
                            </div>
                        </div>
                    </div>
                </div>
            
            <div class="text-end pb-3">
                <button id="signUp_Submit" class="btn btn-blue mt-2" type="submit" [disabled]="!signUpForm.valid"><i class="bi bi-check-circle pe-1"></i> Submit</button>
            </div>
            </form>
        </div>    
    </div>
</div>

<!-- <div  class="custom-modal-sm">

</div> -->