import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles } from '../../constant/role.constant';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
    
    if (route.data['role'].includes(loggedInUser.role.role) || loggedInUser.role.role == Roles.SystemAdmin.role) {
    } else {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
