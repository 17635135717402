import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { Observable, of } from 'rxjs';
import { NokService } from '../nok/nok.service';

@Injectable({
  providedIn: 'root'
})
export class ShareNokService {

  constructor(
    private commonHttpService: CommonHttpService,
    private nokService: NokService
  ) { }

  handleSharedParams(sharedParam: string, loggedInUserId: number, nuggetId: number): Observable<any> {
    if (sharedParam && sharedParam === 'true') {
      const sharedData = {
        userId: loggedInUserId !== null ? loggedInUserId : null,
        nuggetId: nuggetId,
        viewDate: new Date()
      };
      return this.nokService.setSharedNuggetData(sharedData);
    } else {
      return of(null); 
    }
  }
  


}
