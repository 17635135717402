<!--dashboard-wrapper-->
<div id="dashboard_Div">
    <div class="wrapper_space">
        <div class="row align-items-center">
            <div class="col-md-5 col-12">
                <h2 class="heading">Hi {{loggedInUser.firstName}}, Welcome back!</h2>
            </div>
            <div class="col-md-7 col-12">
                <div class="d-flex align-items-center justify-content-md-end">
                    <!-- <span class="pe-2 fw-bold">Period:</span> -->
                    <div class="row justify-content-md-end">
                       
                        <div class="col-6  mb-2 mb-md-0">
                            <div class="custom-input-lg position-relative">
                                <div class="input-container">
                                    <input id="dashboard_StartDate" (cut)="donotCut($event)" autocomplete="off"
                                        name="NuggetStartDate" [(ngModel)]="startDate" type="text" class="form-control"
                                        placeholder="Select Start Date" bsDatepicker #nuggetStartDate="ngModel"
                                        [required]="isSubmit" onkeydown="event.preventDefault()">
                                    <i class="bi bi-calendar4-week calendar-icon"></i>
                                </div>
                                <div class="mb-2"
                                    *ngIf="isSubmit && nuggetStartDate.invalid && (nuggetStartDate.dirty || nuggetStartDate.touched)"
                                    class="text-danger">
                                    <div *ngIf="nuggetStartDate.errors && nuggetStartDate.errors['required']">
                                        Start date is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6  mb-2 pe-5 mb-md-0 position-relative">
                            <div class="custom-input-lg position-relative">
                                <div class="input-container me-1">
                                    <input id="dashboard_EndDate" autocomplete="off" name="NuggetEndDate"
                                        (cut)="donotCut($event)" [(ngModel)]="endDate" type="text" [minDate]="startDate"
                                        class="form-control" placeholder="Select End Date" bsDatepicker
                                        #nuggetEndDate="ngModel" [required]="isSubmit"
                                        onkeydown="event.preventDefault()">
                                    <i class="bi bi-calendar4-week calendar-icon"></i>
                                </div>
                                <div *ngIf="isSubmit && nuggetEndDate.invalid && (nuggetEndDate.dirty || nuggetEndDate.touched)"
                                    class="text-danger mb-0">
                                    <div *ngIf="nuggetEndDate.errors && nuggetEndDate.errors['required']">
                                        End date is required
                                    </div>
                                </div>
                                <div *ngIf="!isEndDateValid()" class="text-danger mb-0">
                                    End date must be greater than start date
                                </div>
                            </div>
                            <button id="dashboard_Search" type="button" (click)="OnSearch()" class="btn btn-blue btn___search">
                                <i class="bi bi-search"></i>
                            </button>
                        </div>
                        <!-- <div class="col-md-1 col-2 mb-2 mb-md-0 d-flex justify-content-end align-items-start">
                            <button id="dashboard_Search" type="button" (click)="OnSearch()" class="btn btn-blue ms-1">
                                <i class="bi bi-search"></i>
                            </button>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>

        <!--card_sm-->
        <div class="row mt-3">
            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 1 || roleId===2 || roleId === 3">
                <div class="card_sm">
                    <h5 *ngIf="roleId === 1">Total Viewed NoKs</h5>
                    <h5 *ngIf="roleId === 2">Total NoKs Reviewed</h5>
                    <h5 *ngIf="roleId === 3">My Viewed NoKs</h5>
                    <div class="mt__36 d-flex align-items-center">
                        <span class="number text-yellow">{{ totalViewedNuggets }}</span>
                        <span class="number_md mt-3 mx-1" *ngIf="roleId === 2">({{reviewPercentage | number
                            :'1.0-0'}}%)</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 2">
                <div class="card_sm">
                    <h5 id="nuggets-title">Checkin NoKs</h5>
                    <div class="mt__36 d-flex align-items-center">
                        <span class="number text-blue">{{ nuggetCheckin }}</span>
                        <span class="number_md mt-3 mx-1" *ngIf="roleId === 2">({{checkinNuggetPercentage | number
                            :'1.0-0'}}%)</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 2">
                <div class="card_sm">
                    <h5 id="nuggets-title">Rejected NoKs</h5>
                    <div class="mt__36 d-flex align-items-center">
                        <span class="number text-red">{{ rejectedNugget }}</span>
                        <span class="number_md mt-3 mx-1" *ngIf="roleId === 2">({{nuggetRejection | number
                            :'1.0-0'}}%)</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 2">
                <div class="card_sm">
                    <h5 id="nuggets-title">Published NoKs</h5>
                    <div class="mt__36 d-flex align-items-center">
                        <span class="number text-green">{{ publishedNugget }}</span>
                        <span class="number_md mt-3 mx-1" *ngIf="roleId === 2">({{nuggetAcceptance | number
                            :'1.0-0'}}%)</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 2">
                <div class="card_sm">
                    <h5 id="nuggets-title">Returned NoKs</h5>
                    <div class="mt__36 d-flex align-items-center">
                        <span class="number" style="color: orange;">{{ returnedNugget }}</span>
                        <span class="number_md mt-3 mx-1" *ngIf="roleId === 2">({{returnedNuggetPercentage | number
                            :'1.0-0'}}%)</span>
                    </div>
                </div>
            </div>
           
            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 1 || roleId === 3">
                <div class="card_sm">
                    <h5 *ngIf="roleId === 1">Total Searched NoKs</h5>
                    <h5 *ngIf="roleId === 3">My Searched NoKs</h5>
                    <span class="number text-blue">{{totalSearchedNuggets}}</span>
                </div>
            </div>


            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 1 || roleId === 3">
                <div class="card_sm">
                    <h5 *ngIf="roleId === 1">Downloaded NoKs</h5>
                    <h5 *ngIf="roleId === 3">My Downloaded NoKs</h5>
                    <span class="number text-purple">{{ downloadedNuggets }}</span>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 1 || roleId === 3">
                <div class="card_sm">
                    <h5 *ngIf="roleId === 1">NoKs Acceptance</h5>
                    <h5 *ngIf="roleId === 3">My NoKs Acceptance</h5>
                    <span class="number text-green">{{ nuggetAcceptance | number :'1.0-0'}}%</span>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xxl-2 col-sm-6" *ngIf="roleId === 1 || roleId === 3">
                <div class="card_sm">
                    <h5 *ngIf="roleId === 1">NoKs Rejection</h5>
                    <h5 *ngIf="roleId === 3">My NoKs Rejection</h5>
                    <span class="number text-red">{{ nuggetRejection | number :'1.0-0'}}%</span>
                </div>
            </div>
</div>
            <!--card_sm-END-->

            <!--card_md-->
            <div class="row">
            <div class="col-12 col-xxl-3 col-md-4 col-lg-4 col-xl-4 col-sm-6" *ngIf="roleId === 1">
                <div class="dashboar_card_md">
                    <h5>Active Users</h5>
                    <span *ngIf="showfilterdata"
                        [ngClass]="{'text-green': activeUserPercentagePastWeek >= 0, 'text-red': activeUserPercentagePastWeek < 0}">
                        <i
                            [ngClass]="{'bi bi-arrow-up-short': activeUserPercentagePastWeek >= 0, 'bi bi-arrow-down': activeUserPercentagePastWeek < 0}"></i>
                        {{ (activeUserPercentagePastWeek >= 0 ? activeUserPercentagePastWeek :
                        -activeUserPercentagePastWeek) | number:'1.0-0'}}% in last week
                    </span>
                    <div class="d-flex justify-content-between align-items-center mt__36">
                        <div>
                            <span class="number_md">{{ activeUserPercentage | number:'1.0-0' }}%</span>
                        </div>
                        <div *ngIf="showfilterdata">
                            <img
                                [src]="activeUserPercentagePastWeek >= 0 ? 'assets/images/line_green.png' : 'assets/images/line_red.png'">
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12 col-xl-3 col-md-6" *ngIf="roleId === 1">
            <div class="dashboar_card_md">
                <h5>Average Session</h5>
                <span *ngIf="!startDate || !endDate || !isSubmit" class="number text-red"><i class="bi bi-arrow-down"></i>12% in last week  </span>
                <div class="d-flex justify-content-between align-items-center mt__36">
                    <div> <span class="number_md">15%</span></div>
                    <div *ngIf="!startDate || !endDate || !isSubmit"> <img src="assets/images/line_red.png"></div>
                </div>                    
            </div>
        </div> -->

            <div class="col-12 col-xxl-3 col-md-4 col-lg-4 col-xl-4 col-sm-6" *ngIf="roleId === 1">
                <div class="dashboar_card_md">
                    <h5>New Users</h5>
                    <span *ngIf="showfilterdata"
                        [ngClass]="{'text-green': newUsersPercentagePastWeek >= 0, 'text-red': newUsersPercentagePastWeek < 0}">
                        <i
                            [ngClass]="{'bi bi-arrow-up-short': newUsersPercentagePastWeek >= 0, 'bi bi-arrow-down': newUsersPercentagePastWeek < 0}"></i>
                        {{ (newUsersPercentagePastWeek >= 0 ? newUsersPercentagePastWeek : -newUsersPercentagePastWeek)
                        | number:'1.0-0' }}% in last week
                    </span>
                    <div class="d-flex justify-content-between align-items-center mt__36">
                        <div>
                            <span class="number_md">{{ newUsersInPastMonth }}</span>
                        </div>
                        <div *ngIf="showfilterdata">
                            <img
                                [src]="newUsersPercentagePastWeek >= 0 ? 'assets/images/line_green.png' : 'assets/images/line_red.png'">
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-xxl-3 col-md-4 col-lg-4 col-xl-4 col-sm-6" *ngIf="roleId === 1">
                <div class="dashboar_card_md">
                    <h5>Pending Users</h5>
                    <span *ngIf="showfilterdata"
                        [ngClass]="{'text-red': pendingUserPercentagePastWeek < 0, 'text-green': pendingUserPercentagePastWeek >= 0}">
                        <i
                            [ngClass]="{'bi bi-arrow-up-short': pendingUserPercentagePastWeek >= 0, 'bi bi-arrow-down': pendingUserPercentagePastWeek < 0}"></i>
                        {{ (pendingUserPercentagePastWeek >= 0 ? pendingUserPercentagePastWeek :
                        -pendingUserPercentagePastWeek) | number:'1.0-0' }}% in last week
                    </span>
                    <div class="d-flex justify-content-between align-items-center mt__36">
                        <div>
                            <span class="number_md">{{ pendingUserPercentage | number:'1.0-0' }}%</span>
                        </div>
                        <div *ngIf="showfilterdata">
                            <img
                                [src]="pendingUserPercentagePastWeek >= 0 ? 'assets/images/line_green.png' : 'assets/images/line_red.png'">
                        </div>
                    </div>
                </div>
            </div>

            <!--card_md-END-->
        </div>

        <div class="card_lg mt-0">
            <div class="card_lg_header">Top 10 NoKs</div>
            <div class="card_lg_body">
                <!---custom-tab-->
                <!--nav-pills-->
                <ul class="nav nav-pills custom-tab mt-2" role="tablist">
                    <li class="nav-item">
                        <a id="dashboard_Viewed" class="nav-link active" data-bs-toggle="pill" href="#Viewed">Viewed</a>
                    </li>
                    <li class="nav-item">
                        <a id="dashboard_Liked" class="nav-link" data-bs-toggle="pill" href="#Liked">Liked</a>
                    </li>
                    <li class="nav-item">
                        <a id="dashboard_Downloaded" class="nav-link" data-bs-toggle="pill"
                            href="#Downloaded">Downloaded</a>
                    </li>
                    <li class="nav-item">
                        <a id="dashboard_Shared" class="nav-link" data-bs-toggle="pill" href="#Shared">Shared</a>
                    </li>
                    <li class="nav-item">
                        <a id="dashboard_Tagged" class="nav-link" data-bs-toggle="pill" href="#Tagged">Tagged</a>
                    </li>
                    <li class="d-flex align-items-center">
                        <div class="row mt-2">
                          <div class="col-2"></div>
                            <div class="col-5 mb-3 md-mb-0">
                                <div class="custom-input-lg position-relative">
                                    <div class="input-container">
                                        <input id="dashboard_Top10StartDate" autocomplete="off" name="NuggetStartDate"
                                            (cut)="donotCut($event)" [(ngModel)]="startDateForTop10" type="text"
                                            class="form-control" placeholder="Select Start Date" bsDatepicker
                                            #nuggetStartDateForTop10="ngModel" [required]="isSubmit"
                                            onkeydown="event.preventDefault()">
                                        <i class="bi bi-calendar4-week calendar-icon"></i>
                                    </div>
                                </div>
                                <div *ngIf="isSubmit && nuggetStartDateForTop10.invalid && (nuggetStartDateForTop10.dirty || nuggetStartDate.touched)"
                                    class="text-danger">
                                    <div
                                        *ngIf="nuggetStartDateForTop10.errors && nuggetStartDateForTop10.errors['required']">
                                        Start date is required
                                    </div>
                                </div>
                            </div>


                            <div class="col-5 mb-3 md-mb-0 pe-5 position-relative">
                                <div class="custom-input-lg position-relative">
                                    <div class="input-container me-1">
                                        <input id="dashboard_Top10EndDate" autocomplete="off"
                                            name="NuggetEndDateForTop10" (cut)="donotCut($event)"
                                            [(ngModel)]="endDateForTop10" type="text" class="form-control"
                                            placeholder="Select End Date" bsDatepicker #nuggetEndDateForTop10="ngModel"
                                            [required]="isSubmit" [minDate]="startDateForTop10"
                                            onkeydown="event.preventDefault()">
                                        <i class="bi bi-calendar4-week calendar-icon"></i>
                                    </div>
                                    <div *ngIf="isSubmit && nuggetEndDateForTop10.invalid && (nuggetEndDateForTop10.dirty || nuggetEndDate.touched)"
                                        class="text-danger mb-0">
                                        <div
                                            *ngIf="nuggetEndDateForTop10.errors && nuggetEndDateForTop10.errors['required']">
                                            End date is required
                                        </div>
                                    </div>
                                    <div *ngIf="!isEndDateValidForTop10()" class="text-danger mb-0">
                                        End date must be greater than start date
                                    </div>
                                </div>
                                <button id="dashboard_SearchTop10" type="button" (click)="OnSearchTop10()"
                                class="btn btn-blue btn___search">
                                <i class="bi bi-search"></i>
                            </button>
                            </div>
                            <!-- <div class="col-md-1 col-2 mb-3 d-flex justify-content-end align-items-start">
                                <button id="dashboard_SearchTop10" type="button" (click)="OnSearchTop10()"
                                    class="btn btn-blue btn___search">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div> -->
                        </div>
                    </li>
                </ul>
                <!--nav-pills-END-->

                <!--tab-content-->
                <div class="tab-content mt-3">
                    <!--tab-pane-All--->
                    <div id="Viewed" class="tab-pane active">
                        <div class="table-responsive mt-3">
                            <table class="data-table" *ngIf="viewData && viewData.length > 0; else noRecordsTemplate">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Format Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of viewData">
                                        <td>{{ item.title }}</td>
                                        <td>{{ getFormatType(item.formatType) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noRecordsTemplate>
                                <div class="mt-3">
                                    No Records
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <!--tab-pane-all-END-->


                    <!--tab-pane-Active-->
                    <div id="Liked" class="tab-pane fade">
                        <div class="table-responsive mt-3">
                            <table class="data-table" *ngIf="likeData && likeData.length > 0; else noRecordsTemplate">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Format Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of likeData">
                                        <td>{{ item.title }}</td>
                                        <td>{{ getFormatType(item.formatType) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noRecordsTemplate>
                                <div class="mt-3">
                                    No Records
                                </div>
                            </ng-template>
                        </div>

                    </div>
                    <!--tab-pane-Active-END-->


                    <!--Inactive-Tab-->
                    <div id="Downloaded" class="tab-pane fade">
                        <div class="table-responsive mt-3">
                            <table class="data-table"
                                *ngIf="downloadData && downloadData.length > 0; else noRecordsTemplate">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Format Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of downloadData">
                                        <td>{{ item.title }}</td>
                                        <td>{{ getFormatType(item.formatType) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noRecordsTemplate>
                                <div class="mt-3">
                                    No Records
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <!--Inactive-Tab-END-->

                    <!--Rejected--Tab-->
                    <div id="Shared" class="tab-pane fade">
                        <div class="table-responsive mt-3">
                            <table class="data-table" *ngIf="shareData && shareData.length > 0; else noRecordsTemplate">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Format Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of shareData">
                                        <td>{{ item.title }}</td>
                                        <td>{{ getFormatType(item.formatType) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noRecordsTemplate>
                                <div class="mt-3">
                                    No Records
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <!--Rejected--Tab-END-->

                    <!--Pending-Tab-->

                    <div id="Tagged" class="tab-pane fade">
                        <div class="table-responsive mt-3">
                            <table class="data-table" *ngIf="tagData && tagData.length > 0; else noRecordsTemplate">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Total Count </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tagData">
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.totalCount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-template #noRecordsTemplate>
                                <div class="mt-3">
                                    No Records
                                </div>
                            </ng-template>
                        </div>
                    </div>

                </div>
                <!--Pending-Tab-->
            </div>
            <!--tab-content-->
        </div>

        <!---custom-tab-END-->

        <!--map-->

        <div class="card_lg mt-5">
            <div class="card_lg_header">NoKs per Country</div>
            <div class="card_lg_body">
                <div class="row">
                    <div class="col-12" style="height: 500px;">
                        <div id="map-container" style="width: 100%; height: 100%;">
                            <app-map-page></app-map-page>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--map-END-->
    </div>
</div>
<!--dashboard-wrapper-END-->