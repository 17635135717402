import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LoggedInUser } from 'src/app/core/models/user/loggedInUser.model';
import { LoginService } from 'src/app/core/services/login/login.service';
interface MenuItem {
  label: string;
  routerLink?: string;
  icon?: string;
  allowedRoles: string[]; // Array of roles allowed to access this page
  subMenu?: boolean;
  subMenuItems?: SubMenuItem[];
}
interface SubMenuItem {
  label: string;
  routerLink: string;
}

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {
   constructor(private router: Router,private loginService: LoginService) { }
  
  loggedInUser: LoggedInUser ;


  ngOnInit(): void {
    this.loginService.getLoginInfo().subscribe((data)=>{
      this.loggedInUser = data;
    })
  }

  toggleSidebar() {
    let element = document.getElementById("mySidenav");
    
    if (element) {
      element.classList.toggle("shrink");
      let element1 = document.getElementById("footer-col-1");
      let element2 = document.getElementById("dashboard-wrapper");

      element2?.classList.toggle("expand");

      if (element1) {
        const footerWidth = element.classList.contains('shrink') ? 'calc(100% - 60px)' : 'calc(100% - 230px)';
        const marginLeft = element.classList.contains('shrink') ? '60px' : '230px';
        element1.style.width = footerWidth;
        element1.style.marginLeft = marginLeft;
      }

      const classCheck = document.getElementsByClassName('shrink').length > 0;
      if (classCheck) {
        element1?.classList.remove("ml-230");
      } else {
        element1?.classList.add("ml-230");
      }
    }
  }
  isNokScreenActive(): boolean {
    const currentRoute = this.router.url;
    return ['/nok/create', '/nok/list', '/nok/edit'].some(route => currentRoute.startsWith(route));
  }
}
