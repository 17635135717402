import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { GetAdminDashboardCardViewModel, GetTopNDashboardDataModel, GetTopNTagDashboardDataModel } from '../../models/nuggets/nuggets.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private commonService: CommonHttpService) { }

  getTopNDashboardData(requestModel : GetTopNDashboardDataModel){
    let url = `/Dashboard/GetTopNDashboardData`;
    return this.commonService.post<GetTopNDashboardDataModel,any>({
      uri : url,
      object : requestModel
    })
  }

  getTopNTagDashboardData(requestModel : GetTopNTagDashboardDataModel){
    let url =  `/Dashboard/GetTopNTagDashboardData`;
    return this.commonService.post<GetTopNTagDashboardDataModel,any>({
      uri : url,
      object : requestModel
    })
  }

  getAdminDashboardCardViewData(requestModel : GetAdminDashboardCardViewModel){
    let url = `/Dashboard/GetAdminDashboardCardViewData`;
    return this.commonService.post<GetAdminDashboardCardViewModel,any>({
      uri : url,
      object : requestModel
    })
  }

  getNuggetsCountByCountryCode() {
    let url = `/External/GetAll`;
    return this.commonService.get<any>({
        uri: url
    });
  }
}
