import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Nuggets, TaxonomyForLabel } from 'src/app/core/models/nuggets/nuggets.model';
import { PersonType } from 'src/app/core/models/person/personType.model';
import { CommonHttpService } from 'src/app/core/services/common-http/common-http.service';
import { NokService } from 'src/app/core/services/nok/nok.service';
import { GetCategoryResponse, GetNuggetByIdModel, NuggetAuthor, NuggetKeyword, NuggetTag } from 'src/app/core/models/category/get-category.model';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/core/services/login/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareNokService } from 'src/app/core/services/share-nok/share-nok.service';
import { LikeNokService } from 'src/app/core/services/like-nok/like-nok.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LoggedInUser } from 'src/app/core/models/user/loggedInUser.model';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  selector: 'app-nugget-preview',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './nugget-preview.component.html',
  styleUrls: ['./nugget-preview.component.scss']
})
export class NuggetPreviewComponent {

  nuggetId: number;
  loggedInUser: LoggedInUser;
  
  isLiked: boolean = false;
  isDisliked: boolean = false;
  nuggetData: Nuggets;
  loading:boolean=true;

  // taxonomy
  hazardTypeList: TaxonomyForLabel[] = [];
  hazardClusterList: TaxonomyForLabel[] = [];
  hazardList: TaxonomyForLabel[] = [];

  pillarList: TaxonomyForLabel[] = [];
  technicalAreaList: TaxonomyForLabel[] = [];
  indicatorsList: TaxonomyForLabel[] = [];

  benchmarksList: TaxonomyForLabel[] = [];
  ihrCapacityList: TaxonomyForLabel[] = [];

  regionList: TaxonomyForLabel[] = [];
  countryList: TaxonomyForLabel[] = [];

  nokSourceList: TaxonomyForLabel[] = [];
  populationList: TaxonomyForLabel[] = [];

  showAllHazardTypes: boolean = false;
  showAllHazardClusters: boolean = false;
  showAllHazards: boolean = false;

  showAllPillars: boolean = false;
  showAllTechnicalArea: boolean = false;
  showAllIndicators: boolean = false;

  showAllWhoBenchmarks: boolean = false;
  showAllCapacities: boolean = false;

  showAllRegions: boolean = false;
  showAllCountries: boolean = false;

  showAllSources: boolean = false;
  showAllPopulations: boolean = false;





  constructor
    (
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private commonHttpService: CommonHttpService,
      private loginService: LoginService,
      private sanitizer: DomSanitizer,
      private modalService: NgbModal,
      private nokService: NokService,
      private shareService: ShareNokService,
      private likeDislikeService: LikeNokService,
      private route: ActivatedRoute,
      private loaderService: LoaderService
    ) {

  }

  ngOnInit(): void {
    this.getNuggetId();
    this.loginService.getLoginInfo().subscribe((data) => {
      this.loggedInUser = data;
    })
    
    
  }

  getNuggetId() {
    this.activatedRoute.params.subscribe({
      next: (data: any) => {
        this.nuggetId = data.nuggetId
        this.getNuggetData();
    
      },
      error: (error: Error) => console.log(error)
    });
  }

  getNuggetData() {
    let model: GetNuggetByIdModel =
    {
      nuggetId: this.nuggetId,
      includeRelatedDetails: true
    }
    this.loaderService.show();
    this.nokService.getNuggetById(model).subscribe({
      next: (res: Nuggets) => {
        this.loaderService.hide();
        this.nuggetData = res;
        if (!this.nuggetData.thumbnail && this.nuggetData.nuggetSourceTypes) {
          const sourceWithThumbnail = this.nuggetData.nuggetSourceTypes.find(x => x.thumbnail);
          if (sourceWithThumbnail) {
            this.nuggetData.thumbnail = sourceWithThumbnail.thumbnail;
          }
        }

        this.nuggetData.relatedNuggets =  this.nuggetData.relatedNuggets.slice(0, 3);
        if (this.nuggetData.formatType === 1 && this.nuggetData.textType === 2) {
          const descriptionLines = this.nuggetData.nuggetDescriptionLines;
          if (descriptionLines.length > 0) {
            this.nuggetData.isOrderedList = descriptionLines[0].isOrdered;
            this.nuggetData.isMultilineDescription = descriptionLines.some(line => line.childLines.length > 0);
          }
        }
        this.buildTaxonomy();
        this.loading=false;
      },
      error: (err: any) => {
        this.loaderService.hide();

        console.log("Error" + err);
        this.loading=false;
      }
    })
    
  }

  getNuggetThumbnail() {
    let thumbnail = `${environment.storageUrl}/${environment.containerName}/${environment.nuggetThumbnailPath}/${this.nuggetData.thumbnail}`;
    return thumbnail;
  }
  getNuggetMedia() {
    return `${environment.storageUrl}/${environment.containerName}/${environment.nuggetMediaPath}/${this.nuggetData.media}`;
  }

  redirectToList() {
    this.router.navigate(['/nok/edit', this.nuggetId]);
  }

  buildTaxonomy() {
    this.nuggetData.nuggetHazardTypes.forEach(hazardType => {
      this.hazardTypeList.push({
        id: hazardType.hazardTypeId,
        name: hazardType.name,
        type: "HazardTypes"
      });

      hazardType.nuggetHazardClusters.forEach(cluster => {
        this.hazardClusterList.push({
          id: cluster.hazardClusterId,
          name: cluster.name,
          type: "HazardClusters"
        });

        cluster.nuggetHazardValues.forEach(value => {
          this.hazardList.push({
            id: value.hazardValueId,
            name: value.name,
            type: "HazardValues"
          });
        });
      });
    });

    this.nuggetData.nuggetSourceTypes.forEach(source => {
      this.nokSourceList.push({
        id: source.nokSourceTypeId,
        name: source.name,
        type: "NokSource"
      });
    });

    this.nuggetData.nuggetPersonTypes.forEach(person => {
      this.populationList.push({
        id: person.personTypeId,
        name: person.name,
        type: "Population"
      });

    });
    this.nuggetData.nuggetRegions.forEach(region => {
      this.regionList.push({
        id: region.regionId,
        name: region.name,
        type: "Region"
      });

      region.nuggetCountries.forEach(country => {
        this.countryList.push({
          id: country.countryId,
          name: country.name,
          type: "Country"
        });
      });
    });

    this.nuggetData.nuggetIHRTechnicalAreas.forEach(techArea => {
      this.pillarList.push({
        id: techArea.ihrTechnicalAreaId,
        name: techArea.name,
        type: "IHRTechnicalAreas"
      });

      techArea.nuggetIHRLevels.forEach(ihrLevel => {
        this.technicalAreaList.push({
          id: ihrLevel.ihrLevelId,
          name: ihrLevel.name,
          type: "IHRLevels"
        });

        ihrLevel.nuggetIHRSpecificLevels.forEach(indicator => {
          this.indicatorsList.push({
            id: indicator.ihrSpecificLevelId,
            name: indicator.name,
            type: "IHRSpecificLevels"
          });
        });
      });
    });

    this.nuggetData.nuggetIHRCapacities.forEach(capacity => {
      this.benchmarksList.push({
        id: capacity.ihrCapacityId,
        name: capacity.name,
        type: "IHRCapacities"
      });

      capacity.nuggetIHRSpecificCapacities.forEach(speccapacity => {
        this.ihrCapacityList.push({
          id: speccapacity.ihrSpecificCapacityId,
          name: speccapacity.name,
          type: "IHRSpecificCapacities"
        });
      });
    });
  }

  getRelatedNuggetIcon(formatType: number) {
    let className = "bi bi-journal-text";
    switch (formatType) {
      case 2:
        className = "bi bi-volume-up";
        break;
      case 3:
        className = "bi bi-play-btn";
        break;
    }
    return className;
  }
  getRelatedNuggetIconText(formatType: number) {
    let iconText = "TEXT";
    switch (formatType) {
      case 2:
        iconText = "AUDIO";
        break;
      case 3:
        iconText = "VIDEO";
        break;
    }
    return iconText;
  }
  getRelatedNuggetDivClass(formatType: number) {
    let divClass = "icons circle_text";
    switch (formatType) {
      case 2:
        divClass = "icons circle_audio";
        break;
      case 3:
        divClass = "icons circle_video";
        break;
    }
    return divClass;
  }
  
  getRelatedNuggetTaxonomy(relatedNugget: Nuggets) {
    const topNumber: number = 1;
    const taxonomyList: TaxonomyForLabel[] = [];

    const addTaxonomy = (id: number, type: string, name: string) => {
      taxonomyList.push({ id, type, name });
    };
    relatedNugget.nuggetRegions.slice(0, topNumber).forEach(region => {
      addTaxonomy(region.regionId, 'Region', region.name);

      region.nuggetCountries.slice(0, topNumber).forEach(country => {
        addTaxonomy(country.countryId, 'Country', country.name);
      });
    });
    relatedNugget.nuggetHazardTypes.slice(0, topNumber).forEach(hazardType => {
      addTaxonomy(hazardType.hazardTypeId, 'HazardTypes', hazardType.name);

      hazardType.nuggetHazardClusters.slice(0, topNumber).forEach(cluster => {
        addTaxonomy(cluster.hazardClusterId, 'HazardClusters', cluster.name);

        cluster.nuggetHazardValues.slice(0, topNumber).forEach(value => {
          addTaxonomy(value.hazardValueId, 'HazardValues', value.name);
        });
      });
    });

    relatedNugget.nuggetIHRTechnicalAreas.slice(0, topNumber).forEach(techArea => {
      addTaxonomy(techArea.ihrTechnicalAreaId, 'IHRTechnicalAreas', techArea.name);

      techArea.nuggetIHRLevels.slice(0, topNumber).forEach(ihrLevel => {
        addTaxonomy(ihrLevel.ihrLevelId, 'IHRLevels', ihrLevel.name);

        ihrLevel.nuggetIHRSpecificLevels.slice(0, topNumber).forEach(indicator => {
          addTaxonomy(indicator.ihrSpecificLevelId, 'IHRSpecificLevels', indicator.name);
        });
      });
    });

    relatedNugget.nuggetIHRCapacities.slice(0, topNumber).forEach(capacity => {
      addTaxonomy(capacity.ihrCapacityId, 'IHRCapacities', capacity.name);

      capacity.nuggetIHRSpecificCapacities.slice(0, topNumber).forEach(speccapacity => {
        addTaxonomy(speccapacity.ihrSpecificCapacityId, 'IHRSpecificCapacities', speccapacity.name);
      });
    });

    return taxonomyList;
  }

  toggleHazardTypeView() {
    this.showAllHazardTypes = !this.showAllHazardTypes;
  }
  toggleClusterView() {
    this.showAllHazardClusters = !this.showAllHazardClusters;
  }
  toggleHazardView() {
    this.showAllHazards = !this.showAllHazards;
  }
  togglePillarsView() {
    this.showAllPillars = !this.showAllPillars;
  }

  toggleTechnicalAreasView() {
    this.showAllTechnicalArea = !this.showAllTechnicalArea;
  }
  toggleIndicatorsView() {
    this.showAllIndicators = !this.showAllIndicators;
  }
  toggleWHOBenchmarksView() {
    this.showAllWhoBenchmarks = !this.showAllWhoBenchmarks;
  }
  toggleIhrCapacitiesView() {
    this.showAllCapacities = !this.showAllCapacities;
  }
  toggleRegionView() {
    this.showAllRegions = !this.showAllRegions;
  }
  toggleCountryView() {
    this.showAllCountries = !this.showAllCountries;
  }

  toggleSourceView() {
    this.showAllSources = !this.showAllSources;
  }

  togglePopulationView() {
    this.showAllPopulations = !this.showAllPopulations;
  }
 
  getDescriptionLineClass(descLines: any[]) {
    return descLines.some(x => !x.isOrdered) ? 'unorder-list' : 'order-list';
  }

  getDescriptionChildLineClass(descLines: any[]) {
    return descLines.some(x => !x.isOrdered) ? 'unorder-list-sub-list' : 'order-list order-sub-list';
  }
}
