<div id="profile" class="user_profile">
    <div class="edit_user__header">
        <div>
            <i class="bi bi-person-gear pe-1 cursor-pointer"></i> Profile
        </div>
        <div id="profile_Back" (click)="goBack()"><i class="bi bi-x-lg cursor-pointer"></i></div>
    </div>
    <div class="p-4 mt-5">
        <!-- <div class="edit-user">
            <div class="upload-btn-wrapper edit-icon_upload">
              
                <img [src]="profileImageUrl" alt="" class="img-responsive" style="max-width: 307px; max-height: 307px;">
            </div>
           
        </div> -->
        <form [formGroup]="profileForm">
            <div class="row">
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="firstname" formControlName="firstName"  [class.is-invalid]="profileForm.get('firstName')!.invalid && profileForm.get('firstName')!.touched" readonly>
                        <div *ngIf="profileForm.get('firstName')!.invalid && profileForm.get('firstName')!.touched" class="invalid-feedback">
                            Please provide a first name.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="lastname" formControlName="lastName" [class.is-invalid]="profileForm.get('lastName')!.invalid && profileForm.get('lastName')!.touched" readonly>
                        <div *ngIf="profileForm.get('lastName')!.invalid && profileForm.get('lastName')!.touched" class="invalid-feedback">
                            Please provide a last name.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" formControlName="email" readonly>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Job Title</label>
                        <input type="text" class="form-control" id="jobtitle" formControlName="jobTitle" readonly>
                    </div>
                </div>
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Institution</label>
                        <input type="text" class="form-control" id="institution" formControlName="institution" readonly>
                    </div>
                </div>
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Affiliation</label>
                        <input type="text" class="form-control" id="affiliation" formControlName="affiliation" readOnly>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Country</label>
                        <input type="text" class="form-control" id="country" formControlName="country" readonly>
                    </div>
                </div>
                <div class="col-md-4 col-12 mb-3">
                    <div class="custom-input-lg">
                        <label for="exampleFormControlInput1" class="form-label">Role</label>
                        <input type="text" class="form-control" id="role" formControlName="role" readonly>
                    </div>
                </div>
            </div>
        </form>
    </div>
    
    <div class="hr my-4"></div>
    <!-- <div class="custom-modal-footer text-end"><button class="btn btn-blue mt-2" (click)="UpdateProfile()"><i class="bi bi-pencil-square"></i> Update Profile</button></div> -->
</div>