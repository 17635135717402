<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<ng-container *ngIf="pageLayout == 1">
    <app-header></app-header>
    <div id="dashboard-wrapper" [class.no-margin-for-sidebar]="!showSideBar">
        <router-outlet></router-outlet>
    </div>
    <!-- <app-delete-user-account></app-delete-user-account> -->
    <app-side-bar *ngIf="showSideBar"></app-side-bar>
    <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="pageLayout == 2">
    <app-page-not-found></app-page-not-found>
</ng-container>

<ng-container *ngIf="pageLayout == 3">
    <app-unautorized-access></app-unautorized-access>
</ng-container>

    