import { Injectable } from '@angular/core';
import { LoginInfo } from '../../models/login/login-info.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getToken(tokenType?: string) {

    const loginInfo: LoginInfo = JSON.parse(localStorage.getItem("login-info") || '{}');

    switch(tokenType) {
      case 'accessToken':
        return loginInfo.accessToken;
      case 'idToken':
        return loginInfo.idToken;
      default:
        return loginInfo;
    }
  }

  getValueFromQueryParams(activatedRoute: ActivatedRoute, paramName: string) {
    return activatedRoute.snapshot.params[paramName];
  }
}
