<div class="paginator">
    <div class="m-1" *ngIf="!showAll">
      {{'Showing' }} {{totalRows !== 0 ? ((currentPage - 1) * rowsPerPage) + 1 : 0}} to {{currentPage * rowsPerPage > totalRows ?
      totalRows : currentPage * rowsPerPage}} {{'of' }} {{totalRows}} {{'entries'}}
    </div>
    <div class="m-1" *ngIf="showAll">
      {{'Showing'}} 1 to {{totalRows}} {{'entries'}}
    </div>
    <div class="m-1">
      <button id="tablePagination_First" (click)="onFirstPage()" [disabled]="currentPage === 1 || showAll">{{'first'}}</button>
      <button id="tablePagination_Prev" (click)="onPrevPage()" [disabled]="currentPage === 1 || showAll">{{'previous'}}</button>
      <span class="paginator-pages">
        <button [id]="'tablePagination_Page_' + i" type="button" *ngFor="let pageLink of pageLinks;let i = index" class="m-1" (click)="emitPageChange(pageLink)"
          [ngClass]="{'active-page' : currentPage === pageLink}" [disabled]="showAll">
          {{ pageLink }}
        </button>
      </span>
      <button id="tablePagination_Next" (click)="onNextPage()" [disabled]="currentPage === totalPages || showAll">{{'next'}}</button>
      <button id="tablePagination_Last" class="paginator-last paginator-element link" (click)="onLastPage()"
        [disabled]="currentPage === totalPages || showAll">{{'last'}}</button>
        <button id="tablePagination_ShowAll" class="paginator-last paginator-element link" (click)="showAllRow()" [ngClass]="{'active-page' : showAll}"
        >{{'showAll'}}</button>
    </div>
  </div>