import { Role } from "../models/role/role.model";

export const Roles = {
    SystemAdmin: {
        roleId: 1,
        role: 'System Admin',
        description: 'System Administrator'
    },
    Reviewer: {
        roleId: 2,
        role: 'Reviewer',
        description: 'Reviewer'
    },
    Contributor: {
        roleId: 3,
        role: 'Contributor',
        description: 'Contributor'
    },
    Viewer: {
        roleId: 4,
        role: 'Viewer',
        description: 'Viewer'
    }
}

export const RoleList : Role[] = [
    {
        roleId: 1,
        role: 'System Admin',
        description: 'System Administrator'
    },
    {
        roleId: 2,
        role: 'Reviewer',
        description: 'Reviewer'
    },
    {
        roleId: 3,
        role: 'Contributor',
        description: 'Contributor'
    }
]