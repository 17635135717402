import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonHttpService } from '../common-http/common-http.service';
import { GetCategoryModel, GetNuggetByIdModel, GetNuggetsCommand, GetNuggetBySearchTermModel, ManageNuggetCommand, UpdateStatusCommand, ManageChekinsCommand, GetNuggetsVisualCommand } from '../../models/category/get-category.model';
import { AddLikedNuggetDataModel, DeleteLikedNuggetDataModel, GetLikedNuggetDataModel, Nuggets, SetSharedNuggetDataModel, UpdateLikedNuggetDataModel, SetDownloadNuggetDataModel, SetViewedNuggetDataModel } from '../../models/nuggets/nuggets.model';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { orderBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NokService {
  constructor(private http: HttpClient, private commonService: CommonHttpService) { }

  getAllCategories(requestModel: GetCategoryModel) {
    let url = `/category/getallcategories`
    return this.commonService.post<GetCategoryModel, any>({
      uri: url,
      object: requestModel
    })
  }

  manageNugget(manageNugget: ManageNuggetCommand) {
    let url = `/nugget/managenugget`
    return this.commonService.post<ManageNuggetCommand, any>({
      uri: url,
      object: manageNugget
    })
  }

  uploadNuggetAssets(formData: FormData) {
    let url = `/nugget/uploadnuggetassets`
    return this.commonService.post<FormData, any>({
      uri: url,
      object: formData
    })
  }

  getNuggetById(requestModel: GetNuggetByIdModel) {
    let url = `/nugget/getnuggetbyid`
    return this.commonService.post<GetNuggetByIdModel, any>({
      uri: url,
      object: requestModel
    })
  }

  updateStatus(updateStatusCommand: UpdateStatusCommand) {
    let url = `/nugget/updatestatus`
    return this.commonService.post<UpdateStatusCommand, boolean>({
      uri: url,
      object: updateStatusCommand
    })
  }

  manageCheckins(manageChekinsCommand: ManageChekinsCommand) {
    let url = `/nugget/managecheckins`
    return this.commonService.post<ManageChekinsCommand, boolean>({
      uri: url,
      object: manageChekinsCommand
    })
  }

  recallNugget(updateStatusCommand: UpdateStatusCommand) {
    let url = `/nugget/recallnugget`
    return this.commonService.post<UpdateStatusCommand, boolean>({
      uri: url,
      object: updateStatusCommand
    })
  }

  approveRecallNugget(nuggetId : number) {
    let url = `/nugget/approveRecallRequest/${nuggetId}`
    return this.commonService.post<number, boolean>({
      uri: url,
      message: {
        successMessage: "Recall request has been approved."
      }
    })
  }

  rejectRecallNugget(nuggetId : number) {
    let url = `/nugget/RejectRecallRequest/${nuggetId}`
    return this.commonService.post<number, boolean>({
      uri: url,
      message: {
        successMessage: "Recall request has been rejected."
      }
    })
  }

  getNuggets(requestModel: GetNuggetsCommand) {
    let url = `/nugget/getnuggets`
    return this.commonService.post<GetNuggetsCommand, any>({
      uri: url,
      object: requestModel
    })
  }



  getNuggetBySearchTerm(requestModel: GetNuggetBySearchTermModel): Observable<Nuggets[]> {
    let url = `/Nugget/GetNuggetBySearchTerm`;

    return this.commonService.post<GetNuggetBySearchTermModel, any>({
      uri: url,
      object: requestModel
    })
  }

  setSharedNuggetData(requestModel: SetSharedNuggetDataModel){
    let url = `/Nugget/GetSharedData`;
    return this.commonService.post<SetSharedNuggetDataModel, any>({
      uri: url,
      object: requestModel
    })
  }

  setViewedNuggetData(requestModel: SetViewedNuggetDataModel){
    let url = `/Nugget/GetViewededData`;
    return this.commonService.post<SetViewedNuggetDataModel, any>({
      uri: url,
      object: requestModel
    })
  }

  getLikedNuggetData(requestModel: GetLikedNuggetDataModel){
    let url = `/Nugget/GetLikedData`;
    return this.commonService.post<GetLikedNuggetDataModel, any>({
      uri: url,
      object: requestModel,
      showToastr: false
    })
  }

  addLikedNuggetData(requestModel: AddLikedNuggetDataModel){
    let url = `/Nugget/AddLikedData`;
    return this.commonService.post<AddLikedNuggetDataModel,any>({
      uri: url,
      object: requestModel
    })
  }

  updateLikedNuggetData(requestModel: UpdateLikedNuggetDataModel){
    let url = `/Nugget/UpdateLikedData`;
    return this.commonService.post<UpdateLikedNuggetDataModel,any>({
      uri: url,
      object: requestModel
    })
  }
  addLikedData(requestModel: AddLikedNuggetDataModel){
    let url = `/nugget/addlikeddata`;
    return this.commonService.post<AddLikedNuggetDataModel,any>({
      uri: url,
      object: requestModel
    })
  }
  
  deleteLikedNuggetData(requestModel: DeleteLikedNuggetDataModel){
    let url = `/Nugget/DeleteLikedData`;
    return this.commonService.post<DeleteLikedNuggetDataModel,any>({
      uri: url,
      object: requestModel
    })
  }

  setDownloadNuggetData(requestModel: SetDownloadNuggetDataModel){
    let url = `/Nugget/GetDownloadData`; 
    return this.commonService.post<SetDownloadNuggetDataModel, any>({
      uri: url,
      object: requestModel
    })
  }

  getNuggetsVisual(requestModel: GetNuggetsVisualCommand) {
    let url = `/nugget/getnuggetsvisual`
    return this.commonService.post<GetNuggetsVisualCommand, any>({
      uri: url,
      object: requestModel
    })
  }

}
