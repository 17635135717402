import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonHttpService } from '../common-http/common-http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http : HttpClient, private commonService : CommonHttpService ) { }

  getProfiledetailbyid(){
    return this.commonService.get<any>({
      uri:`/Profile/GetProfile/`,
      object: {},
      showToastr:false
    });
  }

  updateprofile(formData: FormData) {
    return this.commonService.put<FormData, any>({
      uri: `/Profile/UpdateProfile`,
      object: formData,
      showToastr:true,
      message:{
        successMessage: "Profile Updated!",
        errorMessage: "Failed to update profile. Please try again later."
      }
    })
  }
}
