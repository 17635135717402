<app-loader></app-loader>
<div id="nuggetList">
    <div class="wrapper_space">
    <!--Tabular View-->
    <div class="row align-items-center">
        <div class="col-md-6 col-12">
            <h2 class="heading">NoK Management</h2>
        </div>
        <div class="col-md-6 col-12">
            <div class="d-flex align-items-center justify-content-md-end">
                <div class="tabular_view">
                    <ul>
                        <li>
                            <!-- Bind click event to toggle between card and tabular views -->
                            <a id="nuggetList_Card" class="cursor-pointer"
                                [class]="!isTabularView? 'card-active':'cardview-inactive'"
                                (click)="switchView('card')">
                                <i class="bi bi-window-desktop font-size-18"></i><span> Card View</span>
                            </a>
                        </li>
                        <li>
                            <!-- Bind click event to toggle between card and tabular views -->
                            <a id="nuggetList_Tabular" class="cursor-pointer"
                                [class]="isTabularView? 'card-active':'tableview-inactive'"
                                (click)="switchView('table')">
                                <i class="bi bi-list"></i><span>Tabular View</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!--Tabular View-END-->


    <!---custom-tab-->

    <!--nav-pills-->
    <ul class="nav nav-pills custom-tab mt-4" role="tablist">
        <li class="nav-item">
            <a id="nuggetList_All" class="cursor-pointer nav-link" (click)="selectTab('')"
                [class]="selectedTabName ===''? 'active':''">
                All</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('DraftGrid', loggedInUser.role?.role)">
            <a id="nuggetList_Active" class="cursor-pointer nav-link" (click)="selectTab('Draft')"
                [class]="selectedTabName ==='Draft'? 'active':''"> Draft</a>
        </li>
        <li class="nav-item">
            <a id="nuggetList_Submitted" class="cursor-pointer nav-link" (click)="selectTab('Submit')"
                [class]="selectedTabName ==='Submit'? 'active':''"> Submitted</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('CheckInGrid', loggedInUser.role?.role)">
            <a id="nuggetList_CheckIn" class="cursor-pointer nav-link" (click)="selectTab('Checkin')"
                [class]="selectedTabName ==='Checkin'? 'active':''"> Check IN</a>
        </li>
        <li class="nav-item">
            <a id="nuggetList_Returned" class="cursor-pointer nav-link" (click)="selectTab('Return')"
                [class]="selectedTabName ==='Return'? 'active':''"> Returned</a>
        </li>
        <li class="nav-item">
            <a id="nuggetList_Rejected" class="cursor-pointer nav-link" (click)="selectTab('Reject')"
                [class]="selectedTabName ==='Reject'? 'active':''"> Rejected</a>
        </li>
        <li class="nav-item">
            <a id="nuggetList_Published" class="cursor-pointer nav-link" (click)="selectTab('Published')"
                [class]="selectedTabName ==='Published'? 'active':''"> Published</a>
        </li>
        <li class="nav-item" *ngIf="loggedInUser.role?.role == role.SystemAdmin.role">
            <a id="nuggetList_Archived" class="cursor-pointer nav-link" (click)="selectTab('Archived')"
                [class]="selectedTabName ==='Archived'? 'active':''"> Archived</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('RecallGrid', loggedInUser.role?.role)">
            <a id="nuggetList_Recall" class="cursor-pointer nav-link" (click)="selectTab('Recall')"
                [class]="selectedTabName ==='Recall'? 'active':''"> Recall</a>
        </li>
        <li class="btn-add-pos">
            <button id="nuggetList_AddNok" class="btn btn-blue"
                *ngIf="hasPermission('AddNugget', loggedInUser.role?.role)" (click)="redirectToCreateNugget()"><i
                    class="bi bi-plus-circle-fill text-white"></i> <span class="ps-4">Add NoK</span></button>
        </li>
    </ul>

    <!--nav-pills-END-->

    <!--tab-content-->
    <div class="tab-content mt-3">
        <!--tab-pane-All--->
        <div id="All" class="tab-pane active">
            <!--filter-->
            <div class="d-md-flex d-block justify-content-between">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="input-group custom-input-lg">
                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                        <input id="nuggetList_Search" name="NuggetSearch" type="text" #searchInput
                            class="form-control border-start-0 ps-0" [(ngModel)]="searchTerm" placeholder="Search NoK"
                            (input)="onSearch(searchTerm)">

                    </div>

                </div>

            </div>
            <!--filter-END-->

            <div class="table-responsive mt-3" style="min-height: 350px;" *ngIf="isTabularView && totalRows > 0">
                <table class="data-table">
                    <thead>
                        <tr>
                            <th>Title <button id="nuggetList_SortByTitle" class="btn btn-link" (click)="sortBy('Title')"><img
                                        src="assets/images/sort-icon.svg"></button></th>
                            <th>Category</th>
                            
                            <th>Role <button id="nuggetList_SortByRole" class="btn btn-link" (click)="sortBy('SubmittedByRole')"><img
                                        src="assets/images/sort-icon.svg"></button></th>
                            <th>Submited By <button id="nuggetList_SortByUser" class="btn btn-link" (click)="sortBy('SubmittedByUser')"><img
                                        src="assets/images/sort-icon.svg"></button></th>
                            <th>Submission Date <button id="nuggetList_SortByDate" class="btn btn-link" (click)="sortBy('SubmittedDate')"><img
                                        src="assets/images/sort-icon.svg"></button></th>
                            <th>Created By</th>
                            <th>Status <button id="nuggetList_SortByStatus" class="btn btn-link" (click)="sortBy('Status')"><img
                                        src="assets/images/sort-icon.svg"></button>
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let nugget of nuggetList;let i = index">
                            <tr>
                                <td style="max-width: 300px;
                                word-break: break-all;
                                white-space: normal;" class="fw-bold">{{ nugget.title }}</td>

                                <td>
                                    <span [title]="nugget.displayCategory?.tooltip">
                                        {{nugget.displayCategory?.value}}
                                    </span>
                                </td>

                                <td>{{ nugget.submittedByRole }}</td>
                                <td class="fw-bold">{{ nugget.submittedByUser }} </td>
                                <td>{{ nugget.submittedDate ? formatDate(nugget.submittedDate) : '' }}</td>
                                <td>{{ nugget.createdByUser}}</td>
                                <td><i class="bi bi-circle-fill font-size-10"
                                        [class]="getStatusClass(nugget.status, 'text-')"></i>
                                    {{getStatusName(nugget.status)}}</td>
                                <td>
                                    <div class="dropdown">
                                        <i class="bi bi-three-dots-vertical cursor-pointer" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"></i>
                                        <ul class="dropdown-menu custom-drop-menu custom-drop-menu-sm">
                                            <li>
                                                <a [id]="'nuggetList_Edit_' + i" class="dropdown-item"
                                                    [routerLink]="['/nok/edit/', nugget.nuggetId]">
                                                    <i class="bi bi-pencil-square"></i> Edit
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status != 7 && nugget.status != 8 && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_Archive_' + i" class="dropdown-item"
                                                    (click)="archiveNugget(nugget)">
                                                    <i class="bi bi-archive"></i> Archive
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 2 && hasPermission('CheckInNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_CheckIn_' + i" class="dropdown-item"
                                                    (click)="checkInNugget(nugget)">
                                                    <i class="bi bi-bookmark-check"></i> Check IN
                                                </a>
                                            </li>

                                            <li *ngIf="(nugget.status == 2 || nugget.status == 3) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_AssignReviewer_' + i" class="dropdown-item"
                                                    (click)="openReviewerModel(nugget)">
                                                    <i class="bi bi-person-fill-add"></i> Assign Reviewers
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status != 1 && nugget.status != 2 && nugget.status != 8 && hasPermission('MoveToDraftAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_MoveDraft_' + i" class="dropdown-item"
                                                    (click)="moveToDraft(nugget)">
                                                    <i class="bi bi-envelope-arrow-up"></i> Move to Draft
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('ReturnNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_Return_' + i" class="dropdown-item"
                                                    (click)="openReturnModal(nugget)">
                                                    <i class="bi bi-arrow-return-left"></i> Return
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('RejectNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_Reject_' + i" class="dropdown-item"
                                                    (click)="openRejectModal(nugget)">
                                                    <i class="bi bi-x-square"></i> Reject
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('PublicNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_Publish_' + i" class="dropdown-item"
                                                    (click)="publishNugget(nugget)">
                                                    <i class="bi bi-globe"></i> Publish
                                                </a>
                                            </li>

                                            <li *ngIf="(nugget.status == 2 || nugget.status == 6) && loggedInUser.userId == nugget.submittedBy && loggedInUser.role?.role == role.Contributor.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_ARecall_' + i" class="dropdown-item"
                                                    (click)="openRecallModal(nugget)">
                                                    <i class="bi bi-backspace"></i> Recall NoK
                                                </a>
                                            </li>

                                            <li *ngIf="(nugget.status == 8) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_RRecall_' + i" class="dropdown-item"
                                                    (click)="openApproveRecallModal(nugget.title,nugget.nuggetId,openApproveRecall)">
                                                    <i class="bi bi-check-square"></i>Approve Recall
                                                </a>
                                            </li>

                                            <li *ngIf="(nugget.status == 8) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_Reject_' + i" class="dropdown-item"
                                                    (click)="openRejectRecallModal(nugget.title,nugget.nuggetId,openRejectRecall)">
                                                    <i class="bi bi-backspace"></i>Reject Recall
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="d-flex flex-wrap mt-3" *ngIf="!isTabularView && totalRows > 0">
                <div class="w__100_mobile_view" *ngFor="let nugget of nuggetList;let i = index">
                    <div class="user-cards">
                        <ul>
                            <li>
                                <!-- <div class="user-img"><img src="../../../assets/images/person-circle.svg"></div> -->
                                <span class="user-cards-name">{{nugget.title}}</span>
                                <span class="user-email">{{nugget.submittedByUser}} </span>
                                <span class="user-dec" *ngIf="nugget.nuggetAuthors.length > 0"> | &nbsp;{{nugget.nuggetAuthors[0].name}}</span>
                                    
                                <div class="user-edit-icon">
                                    <div class="dropdown">
                                        <i class="bi bi-three-dots-vertical cursor-pointer"
                                            data-bs-toggle="dropdown"></i>
                                        <ul class="dropdown-menu custom-drop-menu custom-drop-menu-sm">
                                            <li>
                                                <a [id]="'nuggetList_EditTabular_' + i" class="dropdown-item"
                                                    [routerLink]="['/nok/edit/', nugget.nuggetId]">
                                                    <i class="bi bi-pencil-square"></i> Edit
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status != 7 && nugget.status != 8 && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_ArchiveTabular_' + i" class="dropdown-item"
                                                    (click)="archiveNugget(nugget)">
                                                    <i class="bi bi-archive"></i> Archive
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 2 && hasPermission('CheckInNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_CheckInTabular_' + i" class="dropdown-item"
                                                    (click)="checkInNugget(nugget)">
                                                    <i class="bi bi-bookmark-check"></i> Check IN
                                                </a>
                                            </li>

                                            <li *ngIf="(nugget.status == 2 || nugget.status == 3) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_AssignReviewerTabular_' + i" class="dropdown-item"
                                                    (click)="openReviewerModel(nugget)">
                                                    <i class="bi bi-person-fill-add"></i> Assign Reviewers
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status != 1  && nugget.status != 8 && hasPermission('MoveToDraftAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_MoveDraftTabular_' + i" class="dropdown-item"
                                                    (click)="moveToDraft(nugget)">
                                                    <i class="bi bi-envelope-arrow-up"></i> Move to Draft
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('ReturnNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_ReturnTabular_' + i" class="dropdown-item"
                                                    (click)="openReturnModal(nugget)">
                                                    <i class="bi bi-arrow-return-left"></i> Return
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('RejectNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_RejectTabular_' + i" class="dropdown-item"
                                                    (click)="openRejectModal(nugget)">
                                                    <i class="bi bi-x-square"></i> Reject
                                                </a>
                                            </li>

                                            <li *ngIf="nugget.status == 3 && hasPermission('PublicNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_PublishTabular_' + i" class="dropdown-item"
                                                    (click)="publishNugget(nugget)">
                                                    <i class="bi bi-globe"></i> Publish
                                                </a>
                                            </li>
                                            <li *ngIf="(nugget.status == 2 || nugget.status == 6) && loggedInUser!=null && loggedInUser.userId == nugget.submittedBy && hasPermission('RecallNoKAction', loggedInUser.role?.role)"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_ARecallTabular_' + i" class="dropdown-item"
                                                    (click)="openRecallModal(nugget)">
                                                    <i class="bi bi-backspace"></i> Recall NoK
                                                </a>
                                            </li>
                                            <li *ngIf="(nugget.status == 8) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_RRecallTabular_' + i" class="dropdown-item"
                                                    (click)="openApproveRecallModal(nugget.title,nugget.nuggetId,openApproveRecall)">
                                                    <i class="bi bi-check-square"></i>Approve Recall
                                                </a>
                                            </li>
                                            <li *ngIf="(nugget.status == 8) && loggedInUser.role?.role == role.SystemAdmin.role"
                                                class="cursor-pointer">
                                                <a [id]="'nuggetList_RejectTabular_' + i" class="dropdown-item"
                                                    (click)="openRejectRecallModal(nugget.title,nugget.nuggetId,openRejectRecall)">
                                                    <i class="bi bi-backspace"></i>Reject Recall
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="user-text-para" [title]="nugget.displayCategory?.tooltip">
                                    {{nugget.displayCategory?.value}}
                                </div>
                            </li>

                        </ul>
                        <div class="user-cards__footer">
                            <span class="badges"
                                [class]="getStatusClass(nugget.status, 'badges__')">{{getStatusName(nugget.status)}}</span>
                            <span class="float-end font-size-12">{{nugget.submittedByRole}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="totalRows === 0">
                <div class="col-6 mx-auto text-center mt-5">
                    <img src="assets/images/not_found.jpg" style="width: 300px;">
                    <h5 class="">
                        No NoKs found.
                    </h5>

                </div>
            </div>
        </div>

        <!--tab-pane-all-END-->
        <app-table-pagination *ngIf="totalRows > 6" [totalRows]="totalRows" [rowsPerPage]="6"
            [currentPage]="currentPage" (pageChange)="onPageChange($event)" (showAllChange)="showAll($event)"
            [(showAll)]="showAllRow">
        </app-table-pagination>

    </div>


    <!--Return/Reject Modal Start-->
    <div class="custom-modal-sm" *ngIf="modal.show">
        <div class="custom-modal-sm-content">
            <div class="text-end"><i id="nuggetList_RModalClose" (click)="closeModal()"
                    class="bi bi-x-lg cursor-pointer font-size-16"></i></div>
            <h5>{{modal.title}}</h5>
            <div class="row" *ngIf="modal.modalOperation != 0">
                <div class="col-12">
                    {{modal.message}}
                    <textarea name="RejectionReason" [(ngModel)]="modal.reason" style="min-height: 100px; resize: none;"
                        class="form-control"></textarea>
                </div>
                <div class="col-12 text-end">
                    <button id="nuggetList_RModalClose2" [disabled]="modal.disableButtons" class="btn btn-gray  mt-2"
                        (click)="closeModal()"><i class="bi bi-x-circle"></i>
                        Cancel</button> &nbsp;
                    <button id="nuggetList_RModalClose2" [disabled]="modal.disableButtons"
                        (click)="returnRejectNugget()" class="btn btn-red  mt-2">
                        <i class="bi bi-check-circle"></i>
                        {{modal.buttonTitle}}</button>
                </div>
            </div>
            <div class="row" *ngIf="modal.modalOperation == 0">
                <div class="col-12">

                    <ng-select name="NuggetReviewers" [items]="reviewerSuggestions$ | async" bindLabel="item"
                        [(ngModel)]="modal.reviewerEmails" [addTag]="false" [multiple]="true" [selectOnTab]="true"
                        [typeahead]="reviewerSearch$" placeholder="Type email to search..."
                        [loadingText]="'Searching...'" [typeToSearchText]="'Type to search...'">
                    </ng-select>
                </div>

                <div class="col-12 text-end" *ngIf="[]">
                    <button id="nuggetList_RModalCloseOperation0" [disabled]="modal.disableButtons"
                        class="btn btn-gray  mt-2" (click)="closeModal()"><i class="bi bi-x-circle"></i>
                        Cancel</button> &nbsp;
                    <button id="nuggetList_RModalSubmitOperation0" [disabled]="modal.disableButtons"
                        (click)="saveReviewerAssignment()" class="btn btn-green  mt-2">
                        <i class="bi bi-check-circle"></i>
                        {{modal.buttonTitle}}</button>
                </div>
            </div>

        </div>
    </div>
    <!--Return/Reject Modal End-->

    <ng-template tabindex="-1" aria-hidden="true" let-modal let-d="dismiss" #openApproveRecall>
        <div class="custom-modal-sm">
            <div class="custom-modal-sm-content">
                <div class="text-end"><i id="nuggetList_ARModalClose" class="bi bi-x-lg cursor-pointer font-size-22"
                        (click)="modal.close()"></i></div>
                <div class="circle-icon bg-danger my-3"><i class="bi bi-trash3"></i></div>
                <p class="text-center mb-5">Are you sure you want to approve recall call for NoK
                    <span class="fw-bold d-block">{{nuggetForRecall}}</span>
                </p>
                <div class="text-center my-4"><a id="nuggetList_ARModalClose2" class="text-black pe-2"
                        (click)="modal.close()">Cancel</a> <button id="nuggetList_ARModalSubmit" class="btn btn-blue"
                        (click)="approveRecall();modal.close();"><i class="bi bi-check-circle pe-1"></i> Yes, Approve
                        Recall</button></div>
            </div>
        </div>
    </ng-template>


    <ng-template tabindex="-1" aria-hidden="true" let-modal let-d="dismiss" #openRejectRecall>
        <div class="custom-modal-sm">
            <div class="custom-modal-sm-content">
                <div class="text-end"><i id="nuggetList_RRModalClose" class="bi bi-x-lg cursor-pointer font-size-22"
                        (click)="modal.close()"></i></div>
                <div class="circle-icon bg-danger my-3"><i class="bi bi-trash3"></i></div>
                <p class="text-center mb-5">Are you sure you want to reject recall call for NoK
                    <span class="fw-bold d-block">{{nuggetForRecall}}</span>
                </p>
                <div class="text-center my-4"><a id="nuggetList_RRModalClose2" class="text-black pe-2"
                        (click)="modal.close()">Cancel</a> <button id="nuggetList_RRModalSubmit" class="btn btn-blue"
                        (click)="rejectRecall();modal.close();"><i class="bi bi-check-circle pe-1"></i> Yes, Reject
                        Recall</button></div>
            </div>
        </div>
   
    </ng-template>
</div>