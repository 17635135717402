import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { PaginationRequest } from '../../models/pagination-request/pagination-request-model';
import { Assistance } from '../../models/assistance/assistance.model';

@Injectable({
  providedIn: 'root'
})
export class AssistanceService {

  constructor(private commonService: CommonHttpService) { }

  getAllAssistance(requestModel : any){
    let url = `/Assistance/GetAssistance`
    return this.commonService.post<any,any>({
      uri: url,
      object: requestModel
    });
  }

  deleteAssistance(assistanceId: number) {
    let url = `/Assistance/DeleteAssistance`
    return this.commonService.post<any,any>({
      uri: url,
      object: { id : assistanceId }
    });
  }
}
