import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sharable-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sharable-link.component.html',
  styleUrls: ['./sharable-link.component.scss']
})
export class SharableLinkComponent {

  @Input({ required: true }) url : string ;
  isMobile: boolean;
  constructor(private activeModal: NgbActiveModal){
    this.isMobile = this.isMobileDevice();
  }

  isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  copyAddress() {
    const urlWithQueryParam = `${this.url}`+'?shared=true';
    navigator.clipboard.writeText(urlWithQueryParam).then(() => {
      this.closeModal();
    }).catch(error => {
      console.error('Could not copy text:', error);
    });
  }

  shareViaWhatsApp() {
    const message = `Check out this link: ${this.url}?shared=true`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
    this.closeModal();
  }

  shareViaSMS() {
    const message = `Check out this link: ${encodeURIComponent(this.url)}`;
    const smsURL = `sms:?&body=${encodeURIComponent(message)}`;
    window.open(smsURL, '_blank');
    this.closeModal();
  }

  shareOnFacebook() {
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`;
    window.open(facebookURL, '_blank');
    this.closeModal();
  }

  shareOnTwitter() {
    const twitterURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.url)}&text=Check%20out%20this%20link:%20${encodeURIComponent(this.url)}`;
    window.open(twitterURL, '_blank');
    this.closeModal();
  }

  shareOnTelegram() {
    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(this.url)}&text=Check%20out%20this%20link:%20${encodeURIComponent(this.url)}`;
    window.open(telegramURL, '_blank');
    this.closeModal();
  }

  shareViaEmail() {
    const subject = encodeURIComponent('Check out this link');
    const body = encodeURIComponent(`Check out this link: ${this.url}?shared=true`);
    const mailtoURL = `mailto:?subject=${subject}&body=${body}`;
    window.open(mailtoURL, '_blank');
    this.closeModal();
  }


  shareOnLinkedIn() {
    const message = encodeURIComponent('Check out this link!');
    const linkedInURL = `https://www.linkedin.com/feed/?shareActive=true&text=${message}%20${encodeURIComponent(this.url)}?shared=true`;
    window.open(linkedInURL, '_blank');
    this.closeModal();
  }
  
  closeModal(){
    this.activeModal.close();
  }
}
