import { Injectable } from '@angular/core';
import { NokService } from '../nok/nok.service';
import { Observable, of } from 'rxjs';
import { AddLikedNuggetDataModel, DeleteLikedNuggetDataModel, UpdateLikedNuggetDataModel } from '../../models/nuggets/nuggets.model';

@Injectable({
  providedIn: 'root'
})
export class LikeNokService {

  constructor(private nokService: NokService) { }

  handleLikeDislike(isLiked: boolean, isDisliked: boolean, nuggetId: number, userId: number, action: 'like' | 'dislike'): Observable<boolean> {
    if (action === 'like') {
      if (isLiked && !isDisliked) {
        return this.deleteLike(userId, nuggetId);
      } else if (!isLiked && isDisliked) {
        return this.updateLike(userId, nuggetId, true);
      } else if (!isLiked && !isDisliked) {
        return this.addLike(userId, nuggetId);
      }
    } else if (action === 'dislike') {
      if (!isLiked && isDisliked) {
        return this.deleteDislike(userId, nuggetId);
      } else if (isLiked && !isDisliked) {
        return this.updateDislike(userId, nuggetId, false);
      } else if (!isLiked && !isDisliked) {
        return this.addDislike(userId, nuggetId);
      }
    }

    // Default return statement
    return of(false);
  }

  private deleteLike(userId: number, nuggetId: number): Observable<boolean> {
    const deleteLikeData: DeleteLikedNuggetDataModel = {
      userId,
      nuggetId,
      isDeleted: true
    };
    return this.nokService.deleteLikedNuggetData(deleteLikeData);
  }

  private updateLike(userId: number, nuggetId: number, isLiked: boolean): Observable<boolean> {
    const updateLikeData: UpdateLikedNuggetDataModel = {
      userId,
      nuggetId,
      isLiked
    };
    return this.nokService.updateLikedNuggetData(updateLikeData);
  }

  private addLike(userId: number, nuggetId: number): Observable<boolean> {
    const addLikeData: AddLikedNuggetDataModel = {
      userId,
      nuggetId,
      isLiked: true,
      viewDate: new Date()
    };
    return this.nokService.addLikedNuggetData(addLikeData);
  }

  private deleteDislike(userId: number, nuggetId: number): Observable<boolean> {
    const deleteDislikeData: DeleteLikedNuggetDataModel = {
      userId,
      nuggetId,
      isDeleted: true
    };
    return this.nokService.deleteLikedNuggetData(deleteDislikeData);
  }

  private updateDislike(userId: number, nuggetId: number, isLiked: boolean): Observable<boolean> {
    const updateDislikeData: UpdateLikedNuggetDataModel = {
      userId,
      nuggetId,
      isLiked
    };
    return this.nokService.updateLikedNuggetData(updateDislikeData);
  }

  private addDislike(userId: number, nuggetId: number): Observable<boolean> {
    const addDislikeData: AddLikedNuggetDataModel = {
      userId,
      nuggetId,
      isLiked: false,
      viewDate: new Date()
    };
    return this.nokService.addLikedNuggetData(addDislikeData);
  }
}
