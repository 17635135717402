<div class="wrapper_space">

    <div>
        <div class="row align-items-center">
            <div class="col-md-6 col-12">
                <h2 class="heading">Assistance</h2>
            </div>
        </div>
    </div>
    <div class="table-responsive mt-3">
        <table class="data-table">
            <thead>
                <tr>
                    <th>
                        Name
                        <button id="assistanceList_Name" class="btn btn-link" (click)="getByName()">
                            <img src="assets/images/sort-icon.svg" />
                        </button>
                    </th>
                    <th>
                        Description
                    </th>

                    <th>
                        Email
                        <button id="assitanceList_GetByDescription" class="btn btn-link" (click)="getByEmail()">
                            <img src="assets/images/sort-icon.svg" />
                        </button>
                    </th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let template of assistanceList;let i = index">
                    <tr>
                        <td> {{ template.firstName + ' ' + template.lastName }} </td>
                        <td> {{ truncateText(template.description) }} </td>
                        <td> {{ template.email }} </td>
                        <td>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical cursor-pointer"
                                    id="assistanceList_dropdownMenuButton1" data-bs-toggle="dropdown"></i>
                                <ul class="dropdown-menu custom-drop-menu custom-drop-menu-sm"
                                    aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <span (click)="openViewTemplates(template,openViewTemplate)"
                                            class="email-body-viewer" [id]="'assistanceList_ViewTemplate_' + i">
                                            <img src="assets/images/eye-icon.svg" alt="View Email Body" />
                                            View
                                        </span>
                                    </li>
                                    <li>
                                        <button [id]="'assistanceList_Delete_' + i" class="dropdown-item"
                                            (click)="openDeleteTemplate(template.id,template.description,openDelete)"><i
                                                class="bi bi-trash3"></i>Delete</button>
                                    </li>
                                </ul>


                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div>
        <app-table-pagination *ngIf="totalRows > 2" [totalRows]="totalRows" [rowsPerPage]="5"
            [currentPage]="currentPage" (pageChange)="onPageChange($event)" (showAllChange)="showAll($event)"
            [(showAll)]="showAllRow">
        </app-table-pagination>
        <!---custom-tab-END-->
    </div>


    <ng-template tabindex="-1" aria-hidden="true" let-modal let-d="dismiss" #openDelete>
        <div class="custom-modal-sm">
            <div class="custom-modal-sm-content">
                <div class="text-end"><i id="assistanceList_DModalClose" class="bi bi-x-lg cursor-pointer font-size-22"
                        (click)="modal.close()"></i></div>
                <div class="circle-icon bg-danger my-3"><i class="bi bi-trash3"></i></div>
                <p class="text-center mb-5">Are you sure you want to delete Assistance request with Description
                    <span class="fw-bold d-block">{{reason}}</span>
                </p>
                <div class="text-center my-4"><a id="assistanceList_DModalClose2" class="text-black pe-2"
                        (click)="modal.close()">Cancel</a> <button id="assistanceList__DModalSubmit"
                        class="btn btn-red" (click)="onDelete(this.id);modal.close();"><i
                            class="bi bi-check-circle pe-1"></i> Delete</button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #openViewTemplate let-modal>
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h5 class="modal-title">Assistance Details</h5>
            </div>
            <div id="assistanceList_ViewBack" (click)="modal.close()"><i
                    class="bi bi-x-lg cursor-pointer font-size-22"></i></div>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label for="firstName" class="col-3 col-form-label">First Name</label>
                <div class="col-9">
                    <input type="text" class="form-control" id="firstName" disabled [value]="firstName" readonly>
                </div>
            </div>

            <div class="row mb-3">
                <label for="lastName" class="col-3 col-form-label">Last Name</label>
                <div class="col-9">
                    <input type="text" class="form-control" id="lastName" disabled [value]="lastName" readonly>
                </div>
            </div>

            <div class="row mb-3">
                <label for="email" class="col-3 col-form-label">Email</label>
                <div class="col-9">
                    <input type="email" class="form-control" id="email" disabled [value]="email" readonly>
                </div>
            </div>

            <div class="row mb-3">
                <label for="description" class="col-3 col-form-label">Description</label>
                <div class="col-9">
                    <textarea class="form-control" id="description" disabled rows="3" [value]="body"
                        readonly></textarea>
                </div>
            </div>
        </div>
    </ng-template>
</div>