import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive, RouterOutlet, NavigationEnd, Route } from '@angular/router';
import { HeaderComponent } from './layout-components/header/header.component';
import { SideBarComponent } from './layout-components/side-bar/side-bar.component';
import { FooterComponent } from './layout-components/footer/footer.component';
import { HomeContentComponent } from './shared/components/home-content/home-content.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddUserAccountComponent } from './admin/user-management/add-user-account/add-user-account.component';
import { EditUserAccountComponent } from './admin/user-management/edit-user-account/edit-user-account.component';
import { DeleteUserAccountComponent } from './admin/user-management/delete-user-account/delete-user-account.component';
import { TranslateService } from '@ngx-translate/core';
import { ProfilepageComponent } from './shared/components/profilepage/profilepage.component';
import { NuggetItemComponent } from './shared/components/home-content/nugget-item/nugget-item.component';
import { UserService } from './core/services/user/user.service';
import { LoginService } from './core/services/login/login.service';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { filter } from 'rxjs/operators';
import { routes } from './app.routes'; // Import the routes
import { UnautorizedAccessComponent } from './shared/components/unautorized-access/unautorized-access.component';
import { PageLayoutService } from './core/services/page-layout/page-layout.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    HeaderComponent,
    SideBarComponent,
    FooterComponent,
    NuggetItemComponent,
    HomeContentComponent,
    ProfilepageComponent,
    DashboardComponent,
    AddUserAccountComponent,
    EditUserAccountComponent,
    DeleteUserAccountComponent,
    PageNotFoundComponent,
    UnautorizedAccessComponent,

  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    TranslateService
  ]
})
export class AppComponent implements OnInit {
  showSideBar: boolean = false;
  constructor(private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    private pageLayoutService: PageLayoutService
  ) {
    
    this.loginService.getLoginInfo().subscribe((data) => {
      if (data.userId) {
        this.checkUserAuthorization();
      }
      else
      {
        // show unauthorized page.
        if (!this.isPublicRoute(window.location.pathname)) {
          this.pageLayoutService.setPageLayout(3);
        }
      }
    })
  }

  get pageLayout(): number {
    return this.pageLayoutService.getPageLayout();
  }
  ngOnInit(): void {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = event.urlAfterRedirects;
      const isKnownRoute = this.isKnownRoute(currentUrl);
      if(!isKnownRoute)
        {
          this.pageLayoutService.setPageLayout(2);
        }
    });
    
  }

  async checkUserAuthorization() {
    const hasPermission = await this.userService.getUserAuthorization('view_sidebar');
    this.showSideBar = hasPermission;
  }

  private isKnownRoute(url: string): boolean {
    // Define your known routes here
    const knownRoutes = [
      '/home',
      '/unauthorized',
      '/faq',
      '/assistance',
      '/assistance/list',
      '/dashboard',
      '/usermanagement',
      '/usermanagement/add-user',
      '/usermanagement/edit-user/:id',
      '/profile',
      '/settings',
      '/nok/create',
      '/nok/list',
      '/nok/edit/:id',
      '/nok/preview/:id',
      '/home/nok-details/:id',
      '/home/nok-details/:id',
      '/email-notification/list',
      '/email-notification/create',
      '/email-notification/edit/:id',
      // Add other known routes here
    ];

    // Check if the URL matches any of the known routes exactly
    if (knownRoutes.includes(url.split('?')[0])) {
      return true;
    }

    // Check for parameterized routes
    return knownRoutes.some(route => {
      // Convert route to regex pattern
      const regexPattern = route.replace(/:[^\/]+/g, '[^/]+');
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(url.split('?')[0]);
    });
  }

  private isPublicRoute(url: string): boolean {
    // Define your known routes here
    const publicRoutes = [
      '/',
      '/home',
      '/home/nok-details/:id',
      '/home/nok-details-audio/:id',
      '/home/nok-details-video/:id',
      '/home/nok-details/:id',
      '/assistance',
      "/faq"
      // Add other known routes here
    ];

    // Check if the URL matches any of the known routes exactly
    if (publicRoutes.includes(url.split('?')[0])) {
      return true;
    }

    // Check for parameterized routes
    return publicRoutes.some(route => {
      // Convert route to regex pattern
      const regexPattern = route.replace(/:[^\/]+/g, '[^/]+');
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(url.split('?')[0]);
    });
  }

  title = 'The NoK Platform for health emergencies';
}
