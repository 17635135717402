import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailNotificationService } from 'src/app/core/services/email-notification/email-notification.service';
import { MessageService } from 'src/app/core/services/messages/message.service';
import { EmailNotificationModel } from 'src/app/core/models/email-notification/email-notification.model';
import { AutoFocusTarget, MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SortingRequest } from 'src/app/core/models/sorting-request/sorting-request-model';

@Component({
  selector: 'app-email-notification-list',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './email-notification-list.component.html',
  styleUrls: ['./email-notification-list.component.scss']
})
export class EmailNotificationListComponent {
  private emailNotificationService = inject(EmailNotificationService);
  private messageService = inject(MessageService);
  public dialog: MatDialog = inject(MatDialog);

  emailTemplateList: EmailNotificationModel[];
  isPopupVisible: boolean = false;
  popupHtmlContent: string = '';
  modalFocus: AutoFocusTarget = 'dialog';
  sortingRequest: SortingRequest = {
    orderBy: "",
    orderByAscending: true,
  }

  ngOnInit(): void {
    this.getAllEmailTemplate();
  }

  showEmailBody(htmlContent: string) {
    this.popupHtmlContent = htmlContent;
    this.isPopupVisible = true;
    setTimeout(() => {
      const iframe = document.getElementById('popup-iframe') as HTMLIFrameElement;
      iframe.srcdoc = this.popupHtmlContent;
    }, 0);
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  onOutsideClick(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.closePopup();
    }
  }

  getByTag(){
    console.log("tag wala" , this.sortingRequest)
    this.sortingRequest.orderBy = "Tag";
    this.sortingRequest.orderByAscending = !this.sortingRequest.orderByAscending;
    this.getAllEmailTemplate();
  }

  getByDescription(){
    this.sortingRequest.orderBy = "Name";
    this.sortingRequest.orderByAscending = !this.sortingRequest.orderByAscending;
    this.getAllEmailTemplate();
  }

  getBySubject(){
    this.sortingRequest.orderBy = "Subject";
    this.sortingRequest.orderByAscending = !this.sortingRequest.orderByAscending;
    this.getAllEmailTemplate();
  }

  getAllEmailTemplate(){
    this.emailNotificationService.getAllNotificationTemplate(this.sortingRequest).subscribe({
      next: (rsp) => {
        this.emailTemplateList = rsp;
      },
      error: (err) => {
        this.messageService.showErrorMessage(err);
      }
    })
  }

  onDelete(emailTemplateId: number): void {
    this.emailNotificationService
      .deleteEmailNotification(emailTemplateId)
      .subscribe({
        next: (rsp) => {
          this.messageService.showSuccessMessage('Record deleted successfully');
          this.getAllEmailTemplate();
        },
        error: (err) => {
          this.messageService.showErrorMessage(err);
        },
      });
  }

  truncateText(text: string): string {
    if (text.length > 25) {
        return text.substring(0, 25) + '...';
    } else {
        return text;
    }
  }
}
