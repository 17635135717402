
<div class="wrapper_space">
<div>
    <div class="row align-items-center ">
        <div class="col-md-6 col-12">
            <h2 class="heading">Email Notification</h2>
        </div>
        <div class="col-md-6 btnAddTemplate">
            <button style="display: none;" id="email_Add" class="btn btn-blue" [routerLink]="['/email-notification/create']"><i
                    class="bi bi-plus-circle-fill text-white"></i><span class="ps-2">Add Email Template</span></button>
        </div>
    </div>
</div>
<div class="table-responsive mt-3">
    <table class="data-table">
        <thead>
            <tr>
                <th>
                    Tag
                    <button id="email_GetByTag" class="btn btn-link" (click)="getByTag()">
                        <img src="assets/images/sort-icon.svg" />
                    </button>
                </th>
                <th>
                    Description
                    <button id="email_GetByDescription" class="btn btn-link" (click)="getByDescription()">
                        <img src="assets/images/sort-icon.svg" />
                    </button>
                </th>
                <th>
                    Subject
                    <button id="email_GetBySubject" class="btn btn-link" (click)="getBySubject()">
                        <img src="assets/images/sort-icon.svg" />
                    </button>
                </th>
                <th>
                    Email Body
                </th>
                <th style="display: none;">Action</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let template of emailTemplateList;let i = index">
                <tr>
                    <td> {{ template.tag }} </td>
                    <td> {{ truncateText(template.name) }} </td>
                    <td> {{ template.subject }} </td>
                    <td>
                        <div class="email-body-viewer" [id]="'email_ViewTemplate_' + i" (click)="showEmailBody(template.body)">
                            <img src="assets/images/eye-icon.svg" alt="View Email Body" />
                            <span>View</span>
                        </div>
                    </td>
                    <td style="display:none;">
                        <div class="dropdown">
                            <i class="bi bi-three-dots-vertical cursor-pointer" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"></i>
                            <ul class="dropdown-menu custom-drop-menu custom-drop-menu-sm"
                                aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a [id]="'email_Edit_' + i" class="dropdown-item" 
                                        [routerLink]="['/email-notification/edit/', template.emailTemplateID]">
                                        <i class="bi bi-pencil-square"></i> Edit
                                    </a>
                                </li>
                                <li>
                                    <button [id]="'email_Delete_' + i" class="dropdown-item" (click)="onDelete(template.emailTemplateID)"><i
                                            class="bi bi-trash3"></i> Delete</button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<!-- Popup -->
<div class="popup" *ngIf="isPopupVisible" (click)="onOutsideClick($event)">
    <div class="popup-content" style="height:500px;">
        <span id="email_PopupClose" class="close-popup" (click)="closePopup()">&times;</span>
        <iframe id="popup-iframe" width="100%" height="100%"></iframe>
    </div>
</div>
</div>