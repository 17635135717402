import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmailNotificationService } from 'src/app/core/services/email-notification/email-notification.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { EmailNotificationModel } from 'src/app/core/models/email-notification/email-notification.model';
import { MessageService } from 'src/app/core/services/messages/message.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-email-notification',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss'],
})
export class EmailNotificationComponent implements OnInit {
  private emailNotificationService = inject(EmailNotificationService);
  private activatedRoute = inject(ActivatedRoute);
  private utils = inject(UtilsService);
  private messageService = inject(MessageService);
  private sanitizer = inject(DomSanitizer);
  private router = inject(Router);

  emailTemplateId: number;
  title: string;
  emailTemplateForm: FormGroup;
  isEditMode: boolean = false;
  bodyContent: SafeHtml | null = null;

  constructor(private fb: FormBuilder) {
    this.emailTemplateId = Number.parseInt(
      this.utils.getValueFromQueryParams(this.activatedRoute, 'id')
    );

    if (this.emailTemplateId) {
      this.getEmailTemplateById();
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.emailTemplateForm = this.fb.group({
      body: ['', Validators.required],
      name: ['', Validators.required],
      subject: ['', Validators.required],
      tag: ['', Validators.required],
      emailTemplateID: [0],
    });
  }

  ngOnInit(): void {
    // Update the body content in real-time
    this.emailTemplateForm.get('body')?.valueChanges.subscribe((value) => {
      this.updateBodyContent(value);
    });
  }

  updateBodyContent(body: string): void {
    this.bodyContent = body
      ? this.sanitizer.bypassSecurityTrustHtml(body)
      : null;
  }

  getEmailTemplateById() {
    this.emailNotificationService
      .getEmailNotificationTemplateById(this.emailTemplateId)
      .subscribe({
        next: (rsp: EmailNotificationModel) => {
          this.emailTemplateForm.patchValue(rsp);
          this.updateBodyContent(rsp.body);
        },
        error: (err) => {
          this.messageService.showErrorMessage(err);
        },
      });
  }

  onSubmit(): void {
    if (this.emailTemplateForm.invalid) {
      return;
    }

    if (this.emailTemplateId > 0) {
      this.emailNotificationService
        .updateEmailNotificationDetails(this.emailTemplateForm.value)
        .subscribe({
          next: (rsp) => {
            if (rsp) {
              this.router.navigate(["/email-notification/list"]);
              this.messageService.showSuccessMessage(
                'Record updated successfully'
              );
            }
          }
        });
    } else {
      this.emailNotificationService
        .saveEmailNotificationDetails(this.emailTemplateForm.value)
        .subscribe({
          next: (rsp) => {
            if (rsp) {
              this.router.navigate(["/email-notification/list"]);
              this.messageService.showSuccessMessage(
                'Record created successfully'
              );
            }
          }
        });
    }
  }
}
