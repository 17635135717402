<header>
    <div class="d-flex align-items-center pe-3 pe-lg-0">
        <div class="d-flex">
            <div style="cursor: pointer;" class="logo-border">
                <img id="home-logo" src="assets/images/logo-who.png" class=" ms-2" (click)="redirectToHome()">
            </div>
            <div>
                <img id="home-nuggets-logo" src="assets/images/nuggets-logo.png" class="logo-sm ms-2" (click)="redirectToHome()">
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center">
    <div *ngIf="totalPublishedNok > 0" class="ribbon_number" (click)="searchPublishedNoks()">
        <div><span class="text-total">Total<br>NoKs <br> Published<br></span> <span class="total-number">{{totalPublishedNok}}</span> </div>
    </div>

        <ul class="header_links">
            <li class="mr___40" style="margin-right: 60px;">
                <a id="help-link" routerLink="/assistance">
                    <span><i class="bi bi-chat-right-text"></i> <span class="mobile_views"> HELP </span></span>
                </a>
            </li>   
            <!-- <li >
                <a id="faq-link" routerLink="/faq">
                    <span><i class="bi bi-question-circle"></i> <span class="mobile_views"> FAQ</span> </span>
                </a>
            </li>    -->
        </ul>
        <button *ngIf="!IsUserLoggedIn" id="signup-button" class="btn btn-signp" style="display: none;" (click)="signUp()">Sign up</button>
        <button *ngIf="!IsUserLoggedIn" id="signin-button" class="btn btn-blue btn-signIn" (click)="loginUser()">Sign In</button>
        <div class="profile-user cursor-pointer">
            <div class="profile-user cursor-pointer" *ngIf="IsUserLoggedIn">
                <div class="dropdown">
                    <div id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="user-pic">
                            <img [src]="profileImage ? profileImage : 'assets/images/person-circle.svg'">
                        </div>
                        <i class="bi bi-chevron-down d-block d-md-none"></i>
                        <span id="user-name" class="user-name d-none d-md-block">{{loggedInUser.displayName}} <i class="bi bi-chevron-down"></i></span>
                        <span id="user-role" class="user-pos d-none d-md-block ">{{loggedInUser.role?.role}}</span>
                    </div>
                    <ul class="dropdown-menu custom-drop-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="mb-0">
                            <a id="profile-link" routerLink="/profile" class="dropdown-item" href="#">
                                <i class="bi bi-person"></i> Profile
                            </a>
                        </li>
                        <li class="mb-0">
                            <button id="signout-button" class="dropdown-item" (click)="logoutUser()">
                                <i class="bi bi-box-arrow-right"></i> Signout
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
