import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unautorized-access',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './unautorized-access.component.html',
  styleUrls: ['./unautorized-access.component.scss']
})
export class UnautorizedAccessComponent {

  constructor(private loginService: LoginService, private route: ActivatedRoute, private router: Router) { }

  redirectUrl: string;

  ngOnInit() : void
  {
    this.loginService.preRequire();
    // Extract the redirect URL from the query parameters
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirect'] ? decodeURIComponent(params['redirect']) : '';
    });

  }
  loginUser(): void {
    this.loginService.login(this.redirectUrl).subscribe({
      next: () => {
      },
      error: (error) => {
        console.error(error);
      }
    });      
  }
}
