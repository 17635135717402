import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Assistance } from 'src/app/core/models/assistance/assistance.model';
import { AssistanceService } from 'src/app/core/services/assistance/assistance.service';
import { PaginationRequest } from 'src/app/core/models/pagination-request/pagination-request-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TablePaginationComponent } from '../../table-pagination/table-pagination.component';

@Component({
  selector: 'app-assistance-list',
  standalone: true,
  imports: [CommonModule,TablePaginationComponent],
  templateUrl: './assistance-list.component.html',
  styleUrls: ['./assistance-list.component.scss']
})
export class AssistanceListComponent implements OnInit {
  constructor(
    private assistanceService: AssistanceService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.getAllAssistance();
  }

  firstName : string;
  lastName : string;
  email : string;
  body : string;
  id : number;
  reason : string;
  totalRows: number = 0;
  currentPage = 1;
  showAllRow : boolean = false;

  paginationRequest: PaginationRequest = {
    orderBy: "",
    orderByAscending: true,
    pageIndex: 1,
    pageSize: 5,
    columnsAndValuesForFilter: {}
  }
  
  assistanceList: Assistance[];
  
  getByEmail() {
   this.paginationRequest.pageIndex=1
   this.paginationRequest.orderBy="Email";
   this.paginationRequest.orderByAscending =! this.paginationRequest.orderByAscending;
   this.getAllAssistance();
  }

  getByName() {
    this.paginationRequest.pageIndex=1
    this.paginationRequest.orderBy = "FirstName";
    this.paginationRequest.orderByAscending = !this.paginationRequest.orderByAscending;
    this.getAllAssistance();
  }

  getAssistance() {
    this.paginationRequest = {
      pageIndex: 1,
      pageSize:5,
      columnsAndValuesForFilter: {},
      orderBy: "Id",
      orderByAscending: true
    }
    this.getAllAssistance();
  }
  

 
  getAllAssistance() {
    const requestPayload = {
      paginationFilter: this.paginationRequest
    };

    this.assistanceService.getAllAssistance(requestPayload).subscribe({
      next: (result) => {
        this.assistanceList = result.data;
        this.totalRows = result.totalRows;
        this.currentPage = this.paginationRequest.pageIndex;
      },
      error: (error) => {
        console.error('Error fetching assistance list:', error);
      }
    });
  }
  
  onDelete(assistanceId: number): void {
    this.assistanceService
      .deleteAssistance(assistanceId)
      .subscribe({
        next: (rsp) => {
  
          this.getAllAssistance();
        },
      });
  }


  showAll(show : boolean) {

    if(show){
      this.paginationRequest.pageIndex = 1;
      this.paginationRequest.pageSize = 10;
      this.paginationRequest.getAll = true;
      this.getAllAssistance();
    }else{
      this.paginationRequest.getAll=false;
      this.onPageChange(1);
    }
  }

  onPageChange(pageIndex: number) {
    this.currentPage = pageIndex;
    this.paginationRequest = {
      ...this.paginationRequest,
      pageIndex: pageIndex,
      pageSize: 5,
    };
    this.getAllAssistance();
  }

  openViewTemplates( assitance : Assistance ,  template:TemplateRef<any>){
    this.firstName = assitance.firstName;
    this.lastName = assitance.lastName;
    this.email = assitance.email;
    this.body = assitance.description;
    const modalRef = this.modalService.open(template, {
      size: 'md',
      backdrop: 'static',
    });
  }

  openDeleteTemplate(Id: number , body : string , template:TemplateRef<any>){
    this.id = Id;
    this.reason = body;
    const modalRef = this.modalService.open(template, {
      size: 'lg',
      backdrop: 'static',
    });
  }
  
  truncateText(text: string): string {
    if (text.length > 50) {
        return text.substring(0, 50) + '...';
    } else {
        return text;
    }
  }
}
