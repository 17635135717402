// page-layout.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageLayoutService {
  private pageLayout: number = 1; // Default value

  constructor() {}

  getPageLayout(): number {
    return this.pageLayout;
  }

  setPageLayout(layout: number): void {
    this.pageLayout = layout;
  }
}