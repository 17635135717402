import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { toasterConfig } from "../../constant/constants";

@Injectable({
    providedIn: 'root'
})

export class MessageService {
    constructor(
        private toastr: ToastrService,
        private translateService : TranslateService
    ){}
    toasterConfig = toasterConfig;

    /**
     * @description
     * @param message  used to show message
     * @param title  title of message
     * 
     */

    showSuccessMessage(message: string, title?: string): void {
        var transSave = this.isAvailable(message);
        this.toastr.success(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
    }

    
    showErrorMessage(message: string, title?: string): void {
        // var transSave = this.isAvailable(message);
        this.toastr.error(message ,title, this.toasterConfig);
    }

    showInfoMessage(message: string, title?: string): void {
        var transSave = this.isAvailable(message);
        this.toastr.info(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
    }

    showWarningMessage(message: string, title?: string): void {
        var transSave = this.isAvailable(message);
        this.toastr.warning(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
    }

    isAvailable(message : string): boolean {
        const translation = this.translateService.instant(message);
        return translation !== message;
    }
}