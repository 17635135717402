
<div class="wrapper_space">
<h4>{{ isEditMode ? 'Update' : 'Create' }} Email Template</h4>
<div class="row ">
    <div class="col-md-6">
        <form [formGroup]="emailTemplateForm" (ngSubmit)="onSubmit()">
            <div class="form-group custom-input-lg">
                <!-- Tag should readonly on edit mode, cause we use tag to get template from Db. If user change it, need to make change at many places in code again-->
                <label for="tag" class="form-label">Tag</label>
                <input type="text" id="tag" formControlName="tag" class="form-control" [readonly]="isEditMode" [ngClass]="{'readonly-input': isEditMode}" />
            </div>
            <div class="form-group custom-input-lg">
                <label for="name" class="form-label">Description</label>
                <input type="text" id="name" formControlName="name" class="form-control" />
            </div>
            <div class="form-group custom-input-lg">
                <label for="subject" class="form-label">Email Subject</label>
                <input type="text" id="subject" formControlName="subject" class="form-control" />
            </div>
            <div class="form-group custom-input-lg">
                <label for="body" class="form-label">Email Body</label>
                <textarea id="body" formControlName="body" class="form-control txtAreaBody"></textarea>
            </div>
            <div class="row">
                <div class=" col-6">
                    <button id="email_Back" [routerLink]="['/email-notification/list']"
                        class="btn btn-blue btn btn-blue mt-2"><i class="bi bi-arrow-left pe-1"></i>
                        Back</button>
                </div>
                <div class="col-6 text-end">
                    <button id="email_Submit" type="submit" class="btn btn-blue mt-2"><i class="bi bi-check-circle pe-1"></i>{{ isEditMode
                        ? 'Update' : 'Create' }}</button>
                </div>
            </div>
        </form>
    </div>
    <!--Preview-->
    <div class="col-md-6">
        <h4 class="text-center">Email Template Preview</h4>
        <div *ngIf="bodyContent; else noPreview" [innerHTML]="bodyContent"></div>
        <ng-template #noPreview>
            <div class="card text-center">
                <div class="card-body">
                    <i class="bi bi-eye-slash" style="font-size: 2rem;"></i>
                    <h5 class="card-title mt-2">No Preview Available</h5>
                    <div class="card-text pb-2">Enter content in the Body field to see a live preview here.</div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
</div>