import { RoleList, Roles } from "./role.constant";

export const userAccess : { [key: string]: string [] } = {
    Home: [
        Roles.SystemAdmin.role,
        Roles.Contributor.role,
        Roles.Reviewer.role,
        Roles.Viewer.role
    ],
    dashboard: [
        Roles.SystemAdmin.role,
        Roles.Contributor.role,
        Roles.Reviewer.role,
        Roles.Viewer.role
    ],
    'NoK Management': [
        Roles.SystemAdmin.role,
        Roles.Contributor.role,
        Roles.Reviewer.role,
    ],
    'User Management': [
        Roles.SystemAdmin.role,
    ],
    'Setting': [
        Roles.SystemAdmin.role
    ],
    'Reviewer': [
        'CheckInNoKAction',
        'CheckInGrid',
        'ReturnNoKAction',
        'RejectNoKAction',
        'PublicNoKAction'
    ],
    'Contributor': [

        'AddNugget',
        'RecallGrid',
        'RecallNoKAction',
        'DraftGrid',
    ],
    
}