import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from  '@azure/msal-browser';
import { environment } from './environments/environment';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { HttpConfigInterceptor } from './app/authentication/interceptor/http-config.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';

// Expose environment variables to the global window object
(window as any).env = environment;

  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.authOptions.clientId,
        authority: `https://login.microsoftonline.com/${environment.authOptions.tenantId}`, // PPE testing environment.
        redirectUri: `${environment.authOptions.redirectUri}`,
        postLogoutRedirectUri: '/home'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11. Remove this line to use Angular Universal
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }

  
// export function loggerCallback(logLevel: LogLevel, message: string) {
//   console.log(message,"loggerCallback");
// }

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: []
    },
    loginFailedRoute: '/login-failed'
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', environment.authOptions.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, 
      ToastrModule.forRoot({}), 
      TranslateModule.forRoot({}),
      BrowserAnimationsModule,
      MatDialogModule),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
}).catch(err => console.log(err));
