<div class="sidebar-wrapper custom-scroll-bar" id="mySidenav" >
    <div class="close-sidemenu-icon"><i class="bi bi-arrow-left-circle-fill" id="sideNavClose" (click)="toggleSidebar()" ></i></div>
    <div><img src="images/logo-who.png" class="who-logo"></div>
    <ul class="side-menu" id="accordionFlushExample">
        <li><a id="sideNavHome" routerLink="/home" routerLinkActive="active"><i class="bi bi-house-door menu-left-icon"></i><span> Home </span></a></li>
        <li><a id="sideNavDashboard" routerLink="/dashboard" routerLinkActive="active"><i class="bi bi-speedometer2 menu-left-icon"></i><span>Dashboard</span></a></li>
        <li><a id="sideNavNok" routerLink="/nok/list" [class]="isNokScreenActive()?'active':''"><i class="bi bi-grid menu-left-icon"></i><span> NoK Management</span></a></li>
        <li *ngIf="loggedInUser.role?.role === 'System Admin'"><a id="sideNavUserManagement" routerLink="/usermanagement" routerLinkActive="active"><i class="bi bi-person menu-left-icon"></i><span> User Management</span></a></li>
        <li *ngIf="loggedInUser.role?.role === 'System Admin'">
            <div class="accordion transparent-bg">
                <div class="accordion-item transparent-bg border-0">
                    <h2 class="accordion-header transparent-bg" id="flush-headingThree">
                        <button id="sideNavSettings" class="accordion-button transparent-bg collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" routerLink="/settings">
                            <i class="bi bi-gear menu-left-icon"></i><span> Settings</span>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse transparent-bg collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body transparent-bg">
                            <ul class="submenu">
                                <li><a id="sideNavSubMenuDatasets" routerLink="/settings" routerLinkActive="active">Standard Datasets</a></li> 
                                <li><a id="sideNavSubMenuAssistance" routerLink="/assistance/list" routerLinkActive="active">Assistance</a></li>
                                <li><a id="sideNavSubMenuEmail" routerLink="/email-notification/list" routerLinkActive="active">Email Notification</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
