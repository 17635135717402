import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
  standalone: true
})
export class GroupByPipe implements PipeTransform {
  transform(collection: any[], property: string): any {
    if (!collection || !Array.isArray(collection)) {
      return collection;
    }

    const groupedCollection = collection.reduce((accumulator, currentValue) => {
      const key = currentValue[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentValue);
      return accumulator;
    }, {});

    return Object.entries(groupedCollection);
  }
}
