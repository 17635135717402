import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { UserManagementComponent } from 'src/app/admin/user-management/user-management.component';
import { CommonHttpService } from 'src/app/core/services/common-http/common-http.service';
import { Assistance } from 'src/app/core/models/assistance/assistance.model';

@Component({
  selector: 'app-assistance',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UserManagementComponent],
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {
  assistanceForm: FormGroup;

  constructor(
    private router: Router, 
    private commonHttpService: CommonHttpService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.assistanceForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      description: ['', Validators.required]
    });
  }

  submitForm() {
    if (this.assistanceForm.valid) {
      this.commonHttpService
        .post<Assistance, Assistance>({
          uri: `/External/AddAssistance`,
          object: this.assistanceForm.value, 
          showToastr: true,
          message: {
            successMessage: 'We have successfully received your assistance request.'
          }
        }).subscribe({
          next: (data : any) => {
            this.router.navigate(['/home']);
          }
        });
      this.resetForm();
    } else {
      this.assistanceForm.markAllAsTouched();
    }
  }

  resetForm() {
    this.assistanceForm.reset();
  }

  navigateToHomeComponent() {
    this.router.navigate(['/home']);
  }
}
