import { Component, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedInUser } from 'src/app/core/models/user/loggedInUser.model';
import { LoginService } from 'src/app/core/services/login/login.service';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule, } from 'ngx-bootstrap/datepicker';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { GetAdminDashboardCardViewModel, GetTopNDashboardDataModel, GetTopNTagDashboardDataModel, NuggetDownloadModel, NuggetLikeModel, NuggetShareModel, NuggetViewModel, TagModel } from 'src/app/core/models/nuggets/nuggets.model';
import { Format } from 'src/app/core/constant/constants';
import { MapPageComponent } from "../../shared/components/home-content/map-page/map-page.component";

@Component({
    selector: 'app-dashboard',
    standalone: true,
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    imports: [CommonModule, FormsModule, BsDatepickerModule, MapPageComponent]
})

export class DashboardComponent implements OnInit {
  constructor(private dashboardService: DashboardService) { }
  private loginService = inject(LoginService);
  loggedInUser: LoggedInUser;
  startDate: Date = new Date();
  endDate: Date = new Date();
  startDateForTop10: Date;
  endDateForTop10: Date;
  isSubmit: boolean = false;
  totalViewedNuggets: number = 0;
  totalSearchedNuggets: number = 0;
  downloadedNuggets: number = 0;
  nuggetCheckin: number = 0;
  nuggetAcceptance: number = 0;
  nuggetRejection: number = 0;
  activeUserPercentage: number = 0;
  pendingUserPercentage: number = 0;
  newUsersInPastMonth: number = 0;
  activeUserPercentagePastWeek: number = 0;
  newUsersPercentagePastWeek: number = 0;
  pendingUserPercentagePastWeek: number = 0;
  reviewPercentage: number = 0;
  checkinNuggetPercentage: number = 0;
  publishedNugget: number = 0;
  rejectedNugget: number = 0;
  returnedNugget: number = 0;
  returnedNuggetPercentage: number = 0;
  roleId: number;
  showfilterdata: boolean = true;

  ngOnInit(): void {
    this.loginService.getLoginInfo().subscribe((data) => {
      this.loggedInUser = data;
      this.roleId = this.loggedInUser.roleId;
    });
    this.startDate?.setMonth(this.startDate.getMonth() - 1)
    const requestModel: GetAdminDashboardCardViewModel = {
      userId: this.loggedInUser.userId,
      startDate: this.startDate,
      endDate: this.endDate,
      roleId: this.roleId
    };
    this.loadDashboardCardView(requestModel);
    this.getDefaultTop10Date();
  }
  
  
  getDefaultTop10Date()
  {
    this.endDateForTop10 = new Date();
    this.startDateForTop10 = new Date();
    this.startDateForTop10.setMonth(this.endDateForTop10.getMonth() - 1);
    this.OnSearchTop10();
  }
  

  isEndDateValid(): boolean {
    if (!this.endDate || !this.startDate) {
      return true;
    }
    return new Date(this.endDate) > new Date(this.startDate);
  }

  isEndDateValidForTop10() {
    if (!this.endDateForTop10 || !this.startDateForTop10) {
      return true;
    }
    return new Date(this.endDateForTop10) > new Date(this.startDateForTop10);
  }

  OnSearch(): void {
    this.isSubmit = true;
    if (!this.isEndDateValid()) {
      return;
    }
    this.handleweeklysearch();
    const requestModel: GetAdminDashboardCardViewModel = {
      userId: this.loggedInUser.userId,
      startDate: this.getFormattedDate(this.startDate!),
      endDate: this.getFormattedDate(this.endDate!),
      roleId: this.loggedInUser.roleId
    };
    this.loadDashboardCardView(requestModel);
  }
  
  handleweeklysearch(){
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    if(this.startDate?.getDate() !== startDate.getDate() || this.endDate?.getDate() !== endDate.getDate()
      || this.startDate.getMonth() !== startDate.getMonth() || this.endDate.getMonth() != endDate.getMonth()
      || this.startDate.getFullYear() != startDate.getFullYear() || this.endDate.getFullYear() != endDate.getFullYear()) {
      this.showfilterdata = false;
    }
    else{
      this.showfilterdata = true;
    }
  }

  loadDashboardCardView(requestModel: GetAdminDashboardCardViewModel | null): void {
    if (requestModel) {
      this.dashboardService.getAdminDashboardCardViewData(requestModel).subscribe({
        next: (response: any) => {
          if (response && response.length > 0) {
            const data = response[0];
            this.totalViewedNuggets = data.totalViewedNuggets;
            this.totalSearchedNuggets = data.totalSearchedNuggets;
            this.downloadedNuggets = data.downloadedNuggets;
            this.nuggetCheckin = data.nuggetCheckin;
            this.nuggetAcceptance = data.nuggetAcceptance;
            this.nuggetRejection = data.nuggetRejection;
            this.activeUserPercentage = data.activeUserPercentage;
            this.pendingUserPercentage = data.pendingUserPercentage;
            this.newUsersInPastMonth = data.newUsersInPastMonth;
            this.activeUserPercentagePastWeek = data.activeUserPercentagePastWeek;
            this.newUsersPercentagePastWeek = data.newUsersPercentagePastWeek;
            this.pendingUserPercentagePastWeek = data.pendingUserPercentagePastWeek;
            this.reviewPercentage = data.reviewPercentage;
            this.checkinNuggetPercentage = data.checkinNuggetPercentage;
            this.publishedNugget = data.publishedNugget;
            this.rejectedNugget = data.rejectedNugget;
            this.returnedNugget = data.returnedNugget;
            this.returnedNuggetPercentage = data.returnedNuggetPercentage;
          }
        },
        error: (error) => {
        },
      });
    }
  }

  dataInput: GetTopNDashboardDataModel;
  dataTagInput: GetTopNTagDashboardDataModel;
  viewData: NuggetViewModel[] = [];
  shareData: NuggetShareModel[] = [];
  likeData: NuggetLikeModel[] = [];
  downloadData: NuggetDownloadModel[] = [];
  tagData: TagModel[] = [];


  OnSearchTop10() {
    this.isSubmit = true;
    if (!this.isEndDateValid()) {
      return;
    }
    this.dataInput = {
      startDate: this.startDateForTop10,
      endDate: this.endDateForTop10,
      topN: 10,
      userId: null,
      role: '',
      includeLikeData: true,
      includeDownloadData: true,
      includeViewData: true,
      includeShareData: true
    }

    this.dashboardService.getTopNDashboardData(this.dataInput).subscribe(
      (data) => {
        this.viewData = data.nuggetViewViewModels;
        this.likeData = data.nuggetsLikeViewModels;
        this.shareData = data.nuggetShareViewModels;
        this.downloadData = data.nuggetDownloadViewModels;
      }
    )

    this.dataTagInput = {
      startDate: this.startDateForTop10,
      endDate: this.endDateForTop10,
    }

    this.dashboardService.getTopNTagDashboardData(this.dataTagInput).subscribe(
      (data) => {
        this.tagData = data;
      }
    )
  }

  getFormatType(format: number): string {
    switch (format) {
      case 1:
        return 'Text';
      case 2:
        return 'Audio';
      case 3:
        return 'Video';
      default:
        return 'Unknown';
    }
  }

  getFormattedDate(date: Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  donotCut(event:Event){
    event.preventDefault();
  }
}
