import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import Extent from '@arcgis/core/geometry/Extent';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { MapColor } from 'src/app/core/constant/mapColor.constant';
import { RelatedCategory } from 'src/app/core/models/category/get-category.model';
import { BubbleColors } from 'src/app/core/constant/bubble-color.constant';

@Component({
  selector: 'app-map-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit {
  constructor(private dashboardService: DashboardService) { }
  customContent: any;
  allCountriesNuggetCount: any[] = [];
  @Output() filterRelatedCategoryChange = new EventEmitter<RelatedCategory>();

  ngOnInit(): void {
    this.getAllNuggetCounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allCountriesNuggetCount'] && this.allCountriesNuggetCount.length > 0) {
      const maxNuggetsElement = this.allCountriesNuggetCount.reduce((maxElement, currentElement) => {
        return currentElement.totalNuggets > maxElement.totalNuggets ? currentElement : maxElement;
      }, this.allCountriesNuggetCount[0]);
      this.addMapLayers();
    }
  }

  getOpacity(totalNuggets: number): number {
    const range = MapColor.find(r => totalNuggets > r.min && totalNuggets <= r.max);
    return range ? range.opacity : 0.2;
  }

  getColor  (percentage: number) {
    if (percentage >= 0.83) {
        return BubbleColors.Color1;
    } else if (percentage >= 0.67) {
        return BubbleColors.Color2;
    } else if (percentage >= 0.5) {
        return BubbleColors.Color3;
    } else if (percentage >= 0.33) {
        return BubbleColors.Color4;
    } else if (percentage >= 0.17) {
        return BubbleColors.Color5;
    } else {
        return BubbleColors.Color6;
    }
};
  getAllNuggetCounts() {
    this.dashboardService.getNuggetsCountByCountryCode().subscribe({
      next: (data: any) => {
        if (data) {
          this.allCountriesNuggetCount = data;
          this.addMapLayers();
        }
      },
      error: (error: any) => {
      }
    });
  }

  getNuggetCount(iso: string) {
    const content = document.createElement('div');
    let count = 0;
    const countryData = this.allCountriesNuggetCount.find((item: any) => item.isO3Code === iso);
    let countryName = "";
    if (countryData) {
      count = countryData.totalNuggets;
      content.innerHTML = count.toString();
       countryName = countryData.countryName
    } else {
      content.innerHTML = 'N/A';
    }
    

    const contentObject = {
      content: content,
      country: countryName
    }
    return contentObject;
  }

  private addMapLayers(): void {
    const basemapUrl = 'https://tiles.arcgis.com/tiles/5T5nSi527N4F7luB/arcgis/rest/services/WHO_Polygon_Basemap_Dark_Grey/VectorTileServer';
    const featureUrl = 'https://services.arcgis.com/5T5nSi527N4F7luB/ArcGIS/rest/services/Detailed_Boundary_ADM0/FeatureServer/0/';

    const defaultSymbolForBaseMap = new SimpleFillSymbol({
      color: [256, 256, 256],
      outline: {
        color: [0, 0, 0, 0.4],
        width: 0.4,
      },
    });

    const newUniqueValueRenderer = new UniqueValueRenderer({
      field: 'ISO_3_CODE',
      defaultSymbol: defaultSymbolForBaseMap,
      defaultLabel: 'ISO_3_CODE',
    });

    const minTotalNuggets = Math.min(...this.allCountriesNuggetCount.map(data => data.totalNuggets));
    const maxTotalNuggets = Math.max(...this.allCountriesNuggetCount.map(data => data.totalNuggets));
    
    this.allCountriesNuggetCount.forEach((data: { isO3Code: string, totalNuggets: number }) => {
      if (data.isO3Code !== '' && data.totalNuggets > 0) {
        const percentage = (data.totalNuggets - minTotalNuggets) / (maxTotalNuggets - minTotalNuggets);
        const color = this.getColor(percentage);

        newUniqueValueRenderer.addUniqueValueInfo(
          data.isO3Code,
          new SimpleFillSymbol({
            color: color,
            outline: {
              color: [0, 0, 0, 0.4],
              width: 0.4,
            }
          })
        );
      }
    });

    const basemapLayer = new VectorTileLayer({
      url: basemapUrl,
      minScale: 1,
      maxScale: 13
    });
    
    const featurelayer = new FeatureLayer({
      url: featureUrl,
      renderer: newUniqueValueRenderer,
      visible: true,
    });

    const map = new Map({
      layers: [basemapLayer, featurelayer],
    });

    const worldExtent = new Extent({
      xmin: -180,
      ymin: -90,
      xmax: 180,
      ymax: 90,
      spatialReference: new SpatialReference({ wkid: 4326 }),
    });

    const view = new MapView({
      map: map,
      container: "viewDiv",
      extent: worldExtent,
      resizeAlign: 'center',
      center: [0, 0],
      constraints: {
        rotationEnabled: false,
        snapToZoom: false,
        maxZoom: 10,
        minZoom: 2,
        geometry: worldExtent,
      },
      popup: {
        viewModel: {
          includeDefaultActions: false
        },
        visibleElements: {
          closeButton: false,
          featureNavigation: false,
        },
        dockOptions: {
          buttonEnabled: false
        },
        collapseEnabled: false,
        
      }
    });
    view.ui.components =["zoom","compass"];

    //To do:- Event handler to fetch and display the related nuggets.
    view.on("click", (event) => {
      view.hitTest(event, {
        include: featurelayer
      }).then((response) => {
        if (response.results.length) {
          const graphic: any = response.results.filter(result => result.type === "graphic")[0];
          if (graphic && graphic.graphic && graphic.graphic.attributes) {
            const findCountry = this.allCountriesNuggetCount.find(x=>x.isO3Code ==  graphic.graphic.attributes.ISO_3_CODE);
            if(findCountry)
            {
              console.log(findCountry);
              this.addCategoryToFilter({
                id : findCountry.countryId,
                count:1,
                name: findCountry.name,
                type:'Country'
              });
            }
            
          }
        }
      });
    })
    

    // Adding the pointer-move event to show popup on hover
    view.on("pointer-move", (event) => {
      view.hitTest(event, {
        include: featurelayer
      }).then((response) => {
        if (response.results.length) {
          const graphic: any = response.results.filter(result => result.type === "graphic")[0];
          if (graphic && graphic.graphic && graphic.graphic.attributes) {
            const country_iso = graphic.graphic.attributes.ISO_3_CODE;
            const contentObject = this.getNuggetCount(country_iso);
            const popup = {
              title: `${contentObject.country}`,
              content: `Total NoKs: ${contentObject.content.innerHTML}`
            };

            view.openPopup({
              title: popup.title,
              content: popup.content,
              location: graphic.mapPoint,
            });
          }
        } else {
          view.closePopup();
        }
      });
    });
    view.on("pointer-leave", (event) => {
      view.hitTest(event).then((response) => {
        view.closePopup()
      })
    })
  }
  
  addCategoryToFilter(relCat: RelatedCategory) {
    console.log(relCat,"relcat");
    this.filterRelatedCategoryChange.emit(relCat);
  }  
}
