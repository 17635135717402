import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NokService } from '../core/services/nok/nok.service';
import { AutoSuggestResponse, GetNuggetsCommand, ManageChekinsCommand, ManageNuggetCommand, NuggetStatus, ReturnRejectReviewerModal, UpdateStatusCommand } from '../core/models/category/get-category.model';
import { ToastrService } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TablePaginationComponent } from '../shared/components/table-pagination/table-pagination.component';
import { LoaderService } from '../core/services/loader/loader.service';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { Observable, Subject, catchError, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../core/services/login/login.service';
import { LoggedInUser } from '../core/models/user/loggedInUser.model';
import { userAccess } from '../core/constant/userAccess.constant';
import { Roles } from '../core/constant/role.constant';
import { Nuggets } from '../core/models/nuggets/nuggets.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nugget-list',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, TablePaginationComponent, LoaderComponent, ReactiveFormsModule, NgSelectModule,],
  templateUrl: './nugget-list.component.html',
  styleUrls: ['./nugget-list.component.scss']
})
export class NuggetListComponent implements OnInit , OnDestroy{

  modal: ReturnRejectReviewerModal = {
    title: "",
    show: false,
    message: "",
    buttonTitle: "",
    modalOperation: NuggetStatus.Return,
    nuggetId: 0,
    reason: "",
    disableButtons: false,
    reviewerEmails: []
  }
  reviewerSuggestions$: Observable<any[]> = of([]);
  reviewerSearch$ = new Subject<string>();
  searchTerm : string = "";
  private searchInputSubject = new Subject<any>();

  totalRows: number = 0;
  currentPage = 1;
  showAllRow: boolean = false;

  isTabularView: boolean = true;
  selectedTabName: string = "";
  getNuggetsCommand: GetNuggetsCommand = {
    nuggetId: 0,
    searchTerm: "",
    selectedTabName: "",
    paginationFilter: {
      orderBy: "NuggetId",
      pageIndex: 1,
      pageSize: 6,
      orderByAscending: false,
      columnsAndValuesForFilter: {}
    }
  }
  loggedInUser: LoggedInUser;
  nuggetForRecall : string;
  nuggetForRecallId : number;

  nuggetList: ManageNuggetCommand[];
  readonly role = Roles;
  constructor(
    private router: Router,
    private nokService: NokService,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private http: HttpClient,
    private modalService: NgbModal,
    private loginService: LoginService
  ) {
  }

  hasPermission(tag: string, role: any): boolean {
    if(role == "System Admin")
      return true;    
    return userAccess[role].includes(tag);
  }

  ngOnInit(): void {
    this.loginService.getLoginInfo().subscribe((data) => {
      this.loggedInUser = data;
    });
    // Get All Nuggets.
    this.getNuggets();
    this.reviewerSuggestions$ = this.getReviewerSuggestions();
    this.searchInputSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.getNuggetsCommand.searchTerm = searchTerm;
        this.getNuggetsCommand.paginationFilter.pageIndex = 1;
        this.getNuggets();
      });
  }

  ngOnDestroy(): void {
    this.searchInputSubject.complete();
  }

  onSearch(searchTerm: string) {
    this.searchInputSubject.next(searchTerm);
  }

  getStatusClass(nuggetStatus: NuggetStatus, prefix = '') {
    let className = '';
    switch (nuggetStatus) {
      case 1:
        className = "gray";
        break;
      case 2:
        className = "blue";
        break;
      case 3:
        className = "yellow";
        break;
      case 4:
        className = "red";
        break;
      case 5:
        className = "red";
        break;
      case 6:
        className = "green";
        break;
      case 7:
        className = "bluedark";
        break;
    }

    if (className != '') {
      className = prefix + className;
    }
    return className;
  }

  getStatusName(nuggetStatus: NuggetStatus) {
    let statusName = '';
    switch (nuggetStatus) {
      case 1:
        statusName = "Draft";
        break;
      case 2:
        statusName = "Submitted";
        break;
      case 3:
        statusName = "Checkin";
        break;
      case 4:
        statusName = "Returned";
        break;
      case 5:
        statusName = "Rejected";
        break;
      case 6:
        statusName = "Published";
        break;
      case 7:
        statusName = "Archived";
        break;
      case 8:
        statusName = "Recalled";
        break;
    }
    return statusName;
  }
  selectTab(tabName: string): void {
    this.selectedTabName = tabName;
    this.getNuggetsCommand.selectedTabName = tabName;
    this.getNuggetsCommand.paginationFilter.pageIndex = 1;
    this.getNuggetsCommand.paginationFilter.columnsAndValuesForFilter = tabName && tabName.length > 0
      ? { 'Status': [tabName] }
      : {};
    this.getNuggets();
    
  }

  // searchNuggets() {
  //   this.getNuggetsCommand.paginationFilter.pageIndex = 1;
  //   this.getNuggets();
  // }

  getNuggets() {
    this.loaderService.show();
    this.nokService.getNuggets(this.getNuggetsCommand).subscribe({
      next: (res) => {
        this.loaderService.hide();
        this.nuggetList = res.data;
        this.totalRows = res.totalRows;

        this.nuggetList.forEach(nugget => {
          let commaSeparatedcategories = [
            ...nugget.nuggetHazardTypes.map(item => item.name),
            ...nugget.nuggetHazardTypes.flatMap(item => item.nuggetHazardClusters.map(cluster => cluster.name)),
            ...nugget.nuggetHazardTypes.flatMap(item => item.nuggetHazardClusters.flatMap(cluster => cluster.nuggetHazardValues.map(value => value.name))),
            ...nugget.nuggetIHRTechnicalAreas.map(area => area.name),
            ...nugget.nuggetIHRTechnicalAreas.flatMap(area => area.nuggetIHRLevels.map(level => level.name)),
            ...nugget.nuggetIHRTechnicalAreas.flatMap(area => area.nuggetIHRLevels.flatMap(level => level.nuggetIHRSpecificLevels.map(specLevel => specLevel.name))),
            ...nugget.nuggetIHRCapacities.map(capacity => capacity.name),
            ...nugget.nuggetIHRCapacities.flatMap(capacity => capacity.nuggetIHRSpecificCapacities.map(specCapacity => specCapacity.name))
          ].join(', ');

          nugget.displayCategory = {
            tooltip: commaSeparatedcategories,
            value: commaSeparatedcategories.length > 10 ? commaSeparatedcategories.substring(0, 10) + '....' : commaSeparatedcategories
          };

        });
      },
      error: (err: any) => {
        this.loaderService.hide();
        console.log("Error while submitting nugget" + err);
      }
    });
  }
  sortBy(columnName: string) {
    this.onPageChange(1);
    // this.getNuggetsCommand.paginationFilter.pageIndex = 1;
    this.getNuggetsCommand.paginationFilter.orderByAscending = columnName === this.getNuggetsCommand.paginationFilter.orderBy ? !this.getNuggetsCommand.paginationFilter.orderByAscending : true;
    this.getNuggetsCommand.paginationFilter.orderBy = columnName;
    this.getNuggets();
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
  }
  archiveNugget(nugget: ManageNuggetCommand) {
    if (confirm("Are you sure want to archive this NoK?")) {
      this.loaderService.show();
      let updateStatusComamnd: UpdateStatusCommand =
      {
        nuggetId: nugget.nuggetId,
        status: NuggetStatus.Archived,
        reason: ''
      };
      this.nokService.updateStatus(updateStatusComamnd).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("Nugget has been archived.");
          this.getNuggets();
        },
        error: (err: any) => {
          this.loaderService.hide();
          console.log("Error while submiiting nugget" + err);
        }
      });
    }
  }

  moveToDraft(nugget: ManageNuggetCommand) {
    if (confirm("Nugget will be moved to draft, Do you want to proceed?")) {
      let updateStatusCommand: UpdateStatusCommand =
      {
        nuggetId: nugget.nuggetId,
        status: NuggetStatus.Draft,
        reason: ''
      };
      this.loaderService.show();

      this.nokService.updateStatus(updateStatusCommand).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("Nugget is moved to draft.");
          this.getNuggets();
        },
        error: (err: any) => {
          this.loaderService.hide();
          console.log("Error while submitting nugget" + err);
        }
      });
    }
  }

  checkInNugget(nugget: ManageNuggetCommand) {
    if (confirm("Nugget status will be changed to check-in, Do you want to proceed?")) {
      this.loaderService.show();

      let updateStatusCommand: UpdateStatusCommand =
      {
        nuggetId: nugget.nuggetId,
        status: NuggetStatus.Checkin,
        reason: ''
      };
      this.nokService.updateStatus(updateStatusCommand).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("Nugget status is changed to check-in.");
          this.getNuggets();
        },
        error: (err: any) => {
          this.loaderService.hide();
          console.log("Error while submitting nugget" + err);
        }
      });
    }
  }

  publishNugget(nugget: ManageNuggetCommand) {
    if (confirm("Are you sure want to publish the NoK?")) {
      this.loaderService.show();

      let updateStatusCommand: UpdateStatusCommand =
      {
        nuggetId: nugget.nuggetId,
        status: NuggetStatus.Published,
        reason: ''
      };
      this.nokService.updateStatus(updateStatusCommand).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("Nugget published successfully.");
          this.getNuggets();
        },
        error: (err: any) => {
          this.loaderService.hide();
          console.log("Error while submitting nugget" + err);
        }
      });
    }
  }

  redirectToCreateNugget() {
    this.router.navigate(['/nok/create']);
  }
  onPageChange(pageIndex: number) {
    this.currentPage = pageIndex;
    this.getNuggetsCommand.paginationFilter.pageIndex = pageIndex;

    this.getNuggets();
  }
  showAll(show: boolean) {
    if (show) {
      this.getNuggetsCommand.paginationFilter.pageIndex = 1;
      this.getNuggetsCommand.paginationFilter.pageSize = this.totalRows,
        this.getNuggetsCommand.paginationFilter.getAll=true;
      this.getNuggets();
    } else {
      this.getNuggetsCommand.paginationFilter.pageSize = 6,
      this.getNuggetsCommand.paginationFilter.getAll=false;
        this.onPageChange(1);
    }
  }
  switchView(viewType: string) {

    if (viewType === 'card') {
      this.isTabularView = false;
    }
    else {
      this.isTabularView = true;
    }
  }
  closeModal() {
    this.modal.show = false;
  }
  openReturnModal(nugget: ManageNuggetCommand) {
    this.modal.nuggetId = nugget.nuggetId;
    this.modal.show = true;
    this.modal.buttonTitle = "Return";
    this.modal.message = "Reason for return";
    this.modal.modalOperation = NuggetStatus.Return;
    this.modal.title = "Return NoK";
    this.modal.reason = '';
  }
  openRejectModal(nugget: ManageNuggetCommand) {
    this.modal.nuggetId = nugget.nuggetId;
    this.modal.show = true;
    this.modal.buttonTitle = "Reject";
    this.modal.message = "Reason for rejection";
    this.modal.modalOperation = NuggetStatus.Reject;
    this.modal.title = "Reject NoK";
    this.modal.reason = '';
  }
  openRecallModal(nugget: ManageNuggetCommand) {
    this.modal.nuggetId = nugget.nuggetId;
    this.modal.show = true;
    this.modal.buttonTitle = "Recall";
    this.modal.message = "Reason for recall";
    this.modal.modalOperation = NuggetStatus.Recall;
    this.modal.title = "Recall NoK";
  }
  openReviewerModel(nugget: ManageNuggetCommand) {
    this.modal.reviewerEmails = [];
    if (nugget.nuggetCheckins != null) {
      this.modal.reviewerEmails = nugget.nuggetCheckins.map(checkin => checkin.email);
    }
    this.modal.nuggetId = nugget.nuggetId;
    this.modal.show = true;
    this.modal.modalOperation = 0;
    this.modal.title = "Assign Reviewers";
    this.modal.buttonTitle = "Assign"
  }
  returnRejectNugget() {
    if (this.modal.nuggetId > 0) {
      let updateStatusCommand: UpdateStatusCommand =
      {
        nuggetId: this.modal.nuggetId,
        status: this.modal.modalOperation,
        reason: this.modal.reason
      };
      this.modal.disableButtons = true;
      if (this.modal.modalOperation == NuggetStatus.Recall) {
        this.nokService.recallNugget(updateStatusCommand).subscribe({
          next: (res: boolean) => {
            this.modal.disableButtons = false;
            this.modal.show = false;

            let successMsg = "Recall request has been made. Once admin approves the request, NoK will move to the draft status."
            this.toastr.success(successMsg);
            this.getNuggets();
          },
          error: (err: any) => {
            this.modal.disableButtons = false;

            this.modal.show = false;
            console.log("Error while submitting nugget" + err);
          }
        });
      }
      else {
        this.nokService.updateStatus(updateStatusCommand).subscribe({
          next: (res: boolean) => {
            this.modal.disableButtons = false;
            this.modal.show = false;

            let successMsg =
              this.modal.modalOperation == NuggetStatus.Return ? "Nugget has been returned for modification." : "Nugget has been rejected.";
            this.toastr.success(successMsg);
            this.getNuggets();
          },
          error: (err: any) => {
            this.modal.disableButtons = false;

            this.modal.show = false;
            console.log("Error while submitting nugget" + err);
          }
        });
      }
    }

  }

  openApproveRecallModal(nugget : string,nuggetId : number , template:TemplateRef<any>){
    this.nuggetForRecall = nugget;
    this.nuggetForRecallId = nuggetId;
    const modalRef = this.modalService.open(template, {
      size: 'lg',
      backdrop: 'static',
    });
  }

  openRejectRecallModal(nugget : string, nuggetId : number ,  template:TemplateRef<any>){
    this.nuggetForRecall = nugget;
    this.nuggetForRecallId = nuggetId;
    const modalRef = this.modalService.open(template, {
      size: 'lg',
      backdrop: 'static',
    });
  }

  approveRecall() {
    this.nokService.approveRecallNugget(this.nuggetForRecallId).subscribe({
      next: () => {
        this.getNuggets();
      }
    });
  }
  
  rejectRecall() {
    this.nokService.rejectRecallNugget(this.nuggetForRecallId).subscribe({
      next: () => {
        this.getNuggets();
      }
    });
  }

  saveReviewerAssignment() {
    let manageCheckinCommand: ManageChekinsCommand = {
      nuggetId: this.modal.nuggetId,
      emails: this.modal.reviewerEmails
    }
    this.nokService.manageCheckins(manageCheckinCommand).subscribe({
      next: (res: boolean) => {
        this.modal.disableButtons = false;
        this.modal.show = false;
        let successMsg = "Reviewers assigned successfully.";
        this.toastr.success(successMsg);
        this.getNuggets();
      },
      error: (err: any) => {
        this.modal.disableButtons = false;
        this.modal.show = false;
        console.log("Error while assigning reviewers." + err);
      }
    })

  }

  private getReviewerSuggestions(): Observable<any[]> {

    return this.reviewerSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term =>
        this.http.post<AutoSuggestResponse>(`${environment.serverUrl}/Nugget/autosuggest`, {
          searchKey: term,
          type: 3
        }).pipe(
          tap(() => console.log('Suggestion API Called')),
          catchError(() => {
            console.log('Error occurred');
            return of({ Message: '', Result: [], StatusCode: 0, Version: '' });
          }),
          // Extracting 'Result' array from the API response
          map((response: AutoSuggestResponse) => response.Result || [])
        )
      )
    );
  }


}

