import { Injectable, inject } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { CommonHttpService } from '../common-http/common-http.service';
import { LoggedInUser } from '../../models/user/loggedInUser.model';
import { Roles } from '../../constant/role.constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpService = inject(CommonHttpService);

  async getLoggedInUserDetails() : Promise<LoggedInUser> {    
    return await lastValueFrom(this.httpService.get<LoggedInUser>({
      uri: `/User/GetClaimsUserDetails`,
      showToastr: true,
      message:{
      }
    }));
  }

  async getUserAuthorization(permissionTag: string) : Promise<boolean> { 
    
    const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}') as LoggedInUser;

    if (loggedInUser.role?.role == Roles.SystemAdmin.role)
      return await true;
    
    return await lastValueFrom(this.httpService.get<boolean>({
      uri: `/User/authorization/${permissionTag}`,
      showToastr: true,
      message:{
      }
    }));
  }
}
