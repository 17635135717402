
<div class="wrapper_space">
<div class="mt-5 faq">
    <div class="row align-items-center">
        <div class="mb-3">
          <button id="faq_Back" class="btn btn-blue" (click)="previousPage()">Back</button>
        </div>
        <div class="col-xl-6 col-lg-8 col-md-12 col-12 mb-5"><h2 class="heading">Frequently asked questions</h2>
            <span class="d-block my-2 text-muted">Last updated: {{ lastUpdated }}</span>
            <h3 class="font-size-22 fw-normal">Got a question about NoK Platform? You’re in the right place.
                Find answers to the most frequently asked questions about NoK.</h3>
        </div>
        <div class="accordion" id="accordionExample" (click)="updateLastUpdated()">
            <div class="accordion-item" *ngFor="let faqItem of faqItems; let i = index">
              <h2 class="accordion-header" [id]="'heading'+i">
                <button class="accordion-button"(click)="toggleAccordion(i)" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse'+i" >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <span>{{ faqItem.question }}</span>
                    <i [ngClass]="{'bi bi-plus-square-fill': !isExpanded[i], 'bi bi-dash-square-fill': isExpanded[i]}"></i>
                  </div>
                </button>
              </h2>
              <div [id]="'collapse'+i" class="accordion-collapse collapse" [class.show]="isExpanded[i]" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ faqItem.answer }}
                </div>
              </div>
            </div>
          </div>
          
  </div>
</div>
</div>
