import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from 'src/app/core/services/login/login.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showSideBar: boolean;

  constructor(private loginService : LoginService) {  
  }

  ngOnInit(): void {
    this.loginService.getLoginInfo().subscribe((data) => {
      if(data.userId){
        this.showSideBar = true;
      }
    })
  }
}
