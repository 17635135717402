import { environment } from "../../../environments/environment";

export const toasterConfig = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: 300,
    hideDuration: 3000,
    timeOut: 3000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    toastClass: 'toaster-style ngx-toastr',
    enableHtml: true
  };

  export enum UserRole {
    SystemAdmin = 1,
    Reviewer = 2,
    Contributor = 3,
    GlobalViewer = 4
  }

  export enum Format {
    Text = 1,
    Audio = 2,
    Video = 3,
  }

  export const serverUrl = environment.serverUrl;
