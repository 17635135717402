<div id="shareLink" class="popup show">
  <div class="content m-0">
    <i id="shareLink_Close" class="bi bi-x-lg share_popup___close_icon" (click)="closeModal()"></i>
    <h5 class="m-0">Share this link via</h5>
      
    <div class="hr my-3"></div>
       
    <div class="icons__share">
      <a id="shareLink_Facebook" class="facebook__icon" (click)="shareOnFacebook()"><i class="bi bi-facebook"></i></a>
      <a id="shareLink_Twitter" class="twitter__icon" (click)="shareOnTwitter()"><i class="bi bi-twitter-x"></i></a>
      <a id="shareLink_Telegram" class="telegram__icon" (click)="shareOnTelegram()"><i class="bi bi-telegram"></i></a>
      <a id="shareLink_Whatsapp" class="whatsapp__icon" (click)="shareViaWhatsApp()"><i class="bi bi-whatsapp"></i></a>
      <a id="shareLink_Sms" *ngIf="isMobile" (click)="shareViaSMS()"><i class="bi bi-chat-left-text-fill"></i></a>
      <a id="shareLink_Gmail" class="gmail__icon" (click)="shareViaEmail()"><i class="bi bi-envelope-fill"></i></a>
      <a id="shareLink_LinkedIn" class="linkedin__icon" (click)="shareOnLinkedIn()"><i class="bi bi-linkedin"></i></a>
    </div>
    <h6>Or copy link</h6>
    <div class="field mb-2">
      <i class="url-icon uil uil-link"></i>
      <input type="text" readonly [value]="url+ '?shared=true'">
      <button id="shareLink_Copy" (click)="copyAddress()">Copy</button>
    </div>
  </div>
</div>
