import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RoleList } from 'src/app/core/constant/role.constant';
import { User } from 'src/app/core/models/user/user.model';
import { Country } from 'src/app/core/models/country/country.model';
import { Router } from '@angular/router';
import { CommonHttpService } from 'src/app/core/services/common-http/common-http.service';
import { CountryService } from 'src/app/core/services/country/country-service';
import { MessageService } from 'src/app/core/services/messages/message.service';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signUpForm !: FormGroup;
  allRoles = RoleList;
  user: User ;
  countryList: Country[] = [];
  status: number;

  constructor(
    private router: Router, 
    private commonHttpService: CommonHttpService,
    private countryService: CountryService,
    private modal: NgbActiveModal,
    private messageService: MessageService
  ){}

  ngOnInit(): void {
    this.resetForm();
    this.prerequisite();
  }

  prerequisite(){
    this.countryService
        .getCountryList()
        .subscribe({
          next: (data : any) => {
            if(data){
              this.countryList = data;
            }
          }
        })
  }
  
  resetForm(){
    this.signUpForm = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      jobtitle: new FormControl(''),
      institution: new FormControl(''),
      affiliation: new FormControl(''),
      countryId: new FormControl('',[Validators.required]),
      accessReason: new  FormControl('',[Validators.required]),
      roleId: new FormControl('',[Validators.required])
    });
  }

  signUpUser(){
    if(!this.signUpForm.invalid){
      this.status = 1;
    }
    const userData: User = {
      ...this.signUpForm.value,
    }
    this.commonHttpService
          .post<User, User>({
            uri: `/External/CreateUserFromSignUp`,
            object: userData, 
            showToastr: true,
            message: {
              successMessage: "Your request for access sent to system admin, Will get back you."
            }
          })
          .subscribe({
            next: (data : any) => {
              this.signUpForm.reset();
              this.resetForm();
              this.navigateToHomePage();
            }
          })
  }

  navigateToHomePage(){
    this.modal.close();
    this.router.navigate(["./"]);
  }


}
