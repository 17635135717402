import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-table-pagination',
  standalone: true,
  imports: [CommonModule,],
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnChanges {
  @Input() totalRows: number = 0;
  @Output() pageChange = new EventEmitter<number>();
  @Output() showAllChange = new EventEmitter<boolean>();
  @Input() showAll : boolean = false;
  @Input() currentPage: number = 1;
  @Input() rowsPerPage: number = 5;
  pageLinks: number[] = [];

  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange['totalRows'] || simpleChange['rowsPerPage'] || simpleChange['currentPage'])  {
      this.resetPageIndex();
    }
  }

  //will return the number of Pages
  get totalPages(): number {
    return Math.ceil(this.totalRows / this.rowsPerPage);
  }

  //reset the indexes to display
  resetPageIndex() {
    this.pageLinks = [];
    let boundaries = this.calculatePageLinkBoundaries(),
      start = boundaries[0],
      end = boundaries[1];

    for (let i = start; i <= end; i++) {
      this.pageLinks.push(i + 1);
    }
  }

  //calculate the boundaries for the page links
  calculatePageLinkBoundaries() {
    let visiblePages = Math.min(5, this.totalPages);

    //calculate range, keep current in middle if necessary
    let start = Math.max(0, Math.ceil(this.currentPage - 1 - visiblePages / 2)),
      end = Math.min(this.totalPages - 1, start + visiblePages - 1);

    //check when approaching to last page
    var delta = 5 - (end - start + 1);
    start = Math.max(0, start - delta);

    return [start, end];
  }

  //will emit event to parent
  emitPageChange(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      this.currentPage = pageNumber;
      this.resetPageIndex();
      this.pageChange.emit(this.currentPage);
    }
  }

  onNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.emitPageChange(this.currentPage + 1);
    }
  }

  onPrevPage(): void {
    if (this.currentPage > 1) {
      this.emitPageChange(this.currentPage - 1);
    }
  }

  onFirstPage(): void {
    this.emitPageChange(1);
  }

  onLastPage(): void {
    this.emitPageChange(this.totalPages);
  }

  showAllRow(): void {
    this.showAll = !this.showAll;
    this.showAllChange.emit(this.showAll);
    this.currentPage = 1;
  }

}
