import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  lastUpdated: string = "May 22, 2023"; // Initialize with default value
  isExpanded: boolean[] = [];

  constructor(private router: Router) { }

  // Function to update lastUpdated date
  updateLastUpdated() {
    const currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();

    this.lastUpdated = `${monthNames[monthIndex]} ${day}, ${year}`;
  }

  toggleAccordion(i: number) {
    this.isExpanded[i] = !this.isExpanded[i]; // Toggle expand/collapse state
  }

  previousPage(){
    this.router.navigate(['../']);
  }

  // Function to handle FAQ update event
  onFaqUpdate() {
    // Call updateLastUpdated function whenever any FAQ is updated
    this.updateLastUpdated();
  }  
  faqItems = [
    {
      question: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      question: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search ?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      question: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      question: "Is Lorem Ipsum meaningful?",
      answer: "No, Lorem Ipsum is not meaningful or coherent. It is a pseudo-Latin text that has no specific meaning, making it suitable for placeholder content."
    },
    {
      question: "Are there variations of Lorem Ipsum?",
      answer: "Yes, there are variations of Lorem Ipsum text available, including Lorem Ipsum generated with specific word counts or customized with certain patterns of text repetition."
    },
    {
      question: "Can Lorem Ipsum be translated?",
      answer: "Since Lorem Ipsum is not coherent Latin text, it cannot be accurately translated. However, translators sometimes attempt to create meaningful translations for creative purposes."
    },
    {
      question: "What is the significance of the phrase 'Lorem Ipsum'?",
      answer: "The phrase 'Lorem Ipsum' has no inherent significance. It was chosen simply because it sounds similar to Latin and does not have any recognizable meaning, allowing it to serve as placeholder text."
    },
    {
      question: "Is Lorem Ipsum still relevant in modern design?",
      answer: "Yes, Lorem Ipsum remains a popular choice for placeholder text in modern design and publishing workflows. Despite its age, it continues to be widely used for its convenience and familiarity."
    }
  ];
  

}
