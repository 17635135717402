import { Injectable, inject } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { Observable, map, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginInfo } from '../../models/login/login-info.model';
import { Country } from '../../models/country/country.model';


@Injectable({
    providedIn: 'root'
})

export class CountryService {

    countryList : Country[] = [];
    
    constructor(private http : CommonHttpService) { }

    getCountryList(): Observable<Country[]> {
        if (this.countryList.length === 0) {

          return this.http.get<Country[]>({
            uri: '/values/countries',
            showToastr: true,
            message: {
              errorMessage: "something went wrong."
            }
          })
        } else {
          return of(this.countryList);
        }
    }
}